import React, { useEffect, useState } from 'react';
import { Button, IconButton, Modal, ModalBody } from "kubra-ux-forge";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import { WelcomeModalSVG } from './welcome-modal-svg';

const isMobile = window.innerWidth <= 768;
export interface WelcomeModalProps {
    closeButtonLabel?: string;
    dataCy?: string;
    headingText: string;
    infoText: string;
    onClose: () => void;
    open: boolean;
    closeIcon: boolean;
}

export const WelcomeModal = ({
    closeButtonLabel = 'Close',
    dataCy = 'welcome-modal',
    headingText,
    infoText,
    onClose,
    open,
    closeIcon
}: WelcomeModalProps) => {
    const [headingTextState, setHeadingTextState] = useState(headingText);
    const [infoTextState, setInfoTextState] = useState(infoText);

    useEffect(() => {
        setHeadingTextState(headingText);
        setInfoTextState(infoText);
    }, [headingText, infoText]);
    
    const handleClose = () => {
        onClose();
    };

    return (
        <div className='modal-container'>
        <Modal open={open} sharpCorners={true} fullScreen ={isMobile}  onBackdropClick={handleClose} dataCy={dataCy}>
            <ModalBody className="welcome-modal-body" data-cy={`${dataCy}-body-container`}>
                { closeIcon && (<div className="welcome-modal-close-button-container" data-cy={`${dataCy}-button-positioner`}>
                    <IconButton
                        onClick={handleClose}
                        name={'x-icon'}
                        aria-label={closeButtonLabel}
                        tabIndex={0}
                        variant='simple'
                        data-testid={`${dataCy}-x-close-button`}
                        data-cy={`${dataCy}-x-close-button`}
                        icon={<FontAwesomeIcon icon={faXmark} data-cy={`${dataCy}-x-close-button-icon`} />}
                     />
                </div>)}
                <div className='welcome-modal-image'>
                <WelcomeModalSVG data-cy={`${dataCy}-main-icon`} />
                </div>
                <div className= 'welcome-modal-body-text-header' data-cy={`${dataCy}-heading-text`}>{headingTextState}</div>
                <div className="welcome-modal-text-content">
                <div className = 'welcome-modal-body-text' data-cy={`${dataCy}-info-text`}>{infoTextState}</div>
                </div>
                <div className='welcome-modal-button-container' >
                <Button onClick={handleClose} dataCy={`${dataCy}-close-button`}>{closeButtonLabel}</Button>
                </div>
            </ModalBody>
        </Modal>
        </div>
    );
};