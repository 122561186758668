import React, { ChangeEvent, useState } from "react";
import {
  LoadingButton,
  InputField,
  LoadingState,
  TextButton,
} from "kubra-ux-forge";
import triangleWarning from "assets/icons/triangle_exclamation.svg";
import { postSms } from "api/IamApi/entities/ezpay";
import { getBiller } from "selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { COMMON_NAMESPACE, SMS_VIEW } from "constants/i18n-namespaces";
import { PLATFORM_NAMESPACE } from "kubra-labels";

export interface ISMSViewProps {
  accountNumber: any;
  barcodeText: any;
  billerName: any;
  cancelHandler: (arg0: string) => void;
}

export const SMSView = (props: ISMSViewProps) => {
  const { t } = useTranslation(SMS_VIEW);
  const [showInvalidPhone, setShowInvalidPhone] = useState(false);
  const [showEmptyPhone, setShowEmptyPhone] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorState, setErrorState] = useState(false);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<LoadingState>("text");
  const [sentSms, setSentSms] = useState(false);
  const biller = useSelector(getBiller);

  const getFormattedPhoneNum = (input: string) => {
    let output = "(";
    // @ts-ignore
    input.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (match, g1, g2, g3) {
      if (g1.length) {
        output += g1;
        if (g1.length === 3) {
          output += ")";
          if (g2.length) {
            output += " " + g2;
            if (g2.length === 3) {
              output += " - ";
              if (g3.length) {
                output += g3;
              }
            }
          }
        }
      }
    }
    );
    return output;
  };

  const updatePhoneNumber = (e: string) => {
    setShowInvalidPhone(false);
    setShowEmptyPhone(false);
    setShowErrorMessage(false);
    setErrorState(false);
    setPhoneNumber(getFormattedPhoneNum(e));
  };

  const isPhone = (phone: string) => {
    if (phone !== undefined && phone.length > 0) {
      if (phone.length !== 10 && phone.length !== 11) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  const sendSms = () => {
    if (phoneNumber === undefined) {
      setShowEmptyPhone(true);
      setErrorState(true);
    } else if (phoneNumber !== undefined && phoneNumber.length === 0) {
      setShowEmptyPhone(true);
      setErrorState(true);
    } else {
      const numPhone = phoneNumber.replace(/\D/g, "");
      if (isPhone(numPhone)) {
        setSending(true);
        setSuccess("loading");
        if (biller?.instanceId != null && biller.instanceId != undefined) {
          const axiosResponse = postSms(
            biller?.instanceId,
            biller?.clientId,
            numPhone
          );
          axiosResponse.then((res) => {
            setSentSms(true);
            if (res.status === 200) {
              setSuccess("success");
            } else {
              setSuccess("text");
              setShowErrorMessage(true);
            }
            setSending(false);
          });
        } else {
          setSuccess("text");
          setShowErrorMessage(true);
        }
      } else {
        setShowInvalidPhone(true);
        setErrorState(true);
      }
    }
  };

  let sentSuccess = false;
  if (success === "success") {
    sentSuccess = true;
  }

  return (
    <div data-cy="sms-view">
      <div className="content-row">
        <div className="left-text-14">{t("sms-disclaimer")}</div>
      </div>
      <div className="content-row">
        <InputField
          defaultValue=""
          errorText=""
          helperText=""
          label={`${t("mobile-number-label")}`}
          onChange={(e:ChangeEvent<HTMLInputElement>) => updatePhoneNumber(e.target.value)}
          value={phoneNumber}
          hasError={errorState}
          title={`${t("mobile-number-title")}`}
          dataCy="input-mobile-number"
        />
        {showInvalidPhone && (
          <div>
            <img
              src={triangleWarning}
              alt="error"
              className="floatLeft"
              aria-hidden={true}
            />
            <div className="errorText">{t("mobile-number-invalid")}</div>
          </div>
        )}
        {showEmptyPhone && (
          <div>
            <img
              src={triangleWarning}
              alt="error"
              className="floatLeft"
              aria-hidden={true}
            />
            <div className="errorText">{t("mobile-number-required")}</div>
          </div>
        )}
        {showErrorMessage && (
          <div>
            <img
              src={triangleWarning}
              alt="error"
              className="floatLeft"
              aria-hidden={true}
            />
            <div className="errorText">{t("error-sending-sms")}</div>
          </div>
        )}
      </div>
      <div className="content-row">
        <div className="content-row-left-button">
          <TextButton
            onClick={() => props.cancelHandler("BarcodeView")}
            className="cancel-button"
            title="cancel button"
            dataCy="button-cancel"
          >
            {t("cancelLabel", { ns: PLATFORM_NAMESPACE })}
          </TextButton>
        </div>
        <div className="content-row-right-button">
          <LoadingButton
            className="send-button"
            onClick={() => {
              sendSms();
            }}
            loadingState={success}
            title="send button"
            dataCy="button-send"
          >
            {t("send", { ns: COMMON_NAMESPACE })}
          </LoadingButton>
        </div>
      </div>
      {sentSuccess && <div title="sent success"></div>}
    </div>
  );
}
