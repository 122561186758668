import React from "react";
import {
  SCHEDULED_SETTLEMENT,
  AUTHORIZED,
  SCHEDULED_AUTHORIZATION,
  ATTEMPTING_AUTHORIZATION,
  RETRYING_AUTHORIZATION,
  AUTHORIZATION_DECLINED,
  SETTLEMENT_REJECTED,
  CANCELLED,
  ABORTED,
  BLOCKED,
  SCHEDULED_REFUND,
  ATTEMPTING_REFUND,
  AUTHORIZED_REFUND,
  REFUNDED,
  REFUND_REJECTED,
  REFUND_CANCELLED,
  REFUND_REVERSAL,
} from "constants/paymentTransactionStatus";
import {
  PAID,
  APPROVED,
  SCHEDULED,
  PENDING,
  FAILED,
  SCHEDULED_REFUND_STATUS,
  ATTEMPTING_REFUND_STATUS,
  AUTHORIZED_REFUND_STATUS,
  REFUNDED_STATUS,
  REFUND_REJECTED_STATUS,
  REFUND_CANCELLED_STATUS,
  REFUND_REVERSAL_STATUS,
} from "constants/paymentStatus";
import { IDonation } from "types/Api/Payment/Donation";
import { ICharity, ICharityState } from "types/charity";
import { SessionSupportedLanguages } from "types/session";
import { BadgeVariant } from "kubra-ux-forge/dist/elements/Badge/Badge";
import FundingOption from "constants/FundingOption";
import google_pay_mark from "assets/google-pay-mark.png";
import apple_pay_mark from "assets/apple-pay-mark.svg";
import paypal_mark from "assets/paypal-mark.png";
import _ from "lodash";

export const getDomain = () => {
  const localTestDomain = "abcenergysandbox.dev.ez-pay.co";
  const result = window.location.host.includes("localhost")
    ? localTestDomain
    : window.location.host;
  return result;
};

export const getSubDomain = () => {
  const domain = getDomain();
  const result = domain.split(".")[0];
  return result;
};

export const upperCaseFirstLetterInString = (str: string) => {
  if (str === null || str === undefined) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function mapCharityToCharityState(
  charities: ICharity[] | undefined
): ICharityState[] {
  if (charities == undefined || charities == null || charities.length === 0) {
    return [];
  }
  return charities.map((charity) => ({
    charity: charity,
    selected: false,
    amount: 0,
  }));
}

export const mappingDonataionResponseToCharityState = (
  donations: IDonation[],
  charties: ICharity[] | undefined
): ICharityState[] => {
  if (donations === null || donations === undefined || donations.length == 0)
    return [];

  // use charity code as name as no reference is available
  if (charties === null || charties === undefined || charties.length == 0) {
    return donations.map((donation) => ({
      charity: { code: donation.charityCode, name: donation.charityCode },
      selected: true,
      amount: donation.amount,
    }));
  }

  const map = new Map(charties.map((charity) => [charity.code, charity]));
  const result = [];
  for (const donation of donations) {
    const charity = map.get(donation.charityCode);
    if (charity) {
      result.push({
        charity: charity,
        selected: true,
        amount: donation.amount,
      });
    } else {
      // use charity code as name
      result.push({
        charity: { code: donation.charityCode, name: donation.charityCode },
        selected: true,
        amount: donation.amount,
      });
    }
  }
  return result;
};

export const mappingPaymentStatus = (status: string) => {
  switch (status?.toLowerCase()) {
    case SCHEDULED_AUTHORIZATION:
      return SCHEDULED;
    case SCHEDULED_SETTLEMENT:
      return SCHEDULED;
    case ATTEMPTING_AUTHORIZATION:
      return PENDING;
    case RETRYING_AUTHORIZATION:
      return PENDING;
    case AUTHORIZED:
      return PENDING;
    case AUTHORIZATION_DECLINED:
      return FAILED;
    case APPROVED:
      return PAID;
    case SETTLEMENT_REJECTED:
      return FAILED;
    case CANCELLED:
      return CANCELLED;
    case ABORTED:
      return CANCELLED;
    case BLOCKED:
      return FAILED;
    case SCHEDULED_REFUND:
      return SCHEDULED_REFUND_STATUS;
    case ATTEMPTING_REFUND:
      return ATTEMPTING_REFUND_STATUS;
    case AUTHORIZED_REFUND:
      return AUTHORIZED_REFUND_STATUS;
    case REFUNDED:
      return REFUNDED_STATUS;
    case REFUND_REJECTED:
      return REFUND_REJECTED_STATUS;
    case REFUND_CANCELLED:
      return REFUND_CANCELLED_STATUS;
    case REFUND_REVERSAL:
      return REFUND_REVERSAL_STATUS;
    default:
      return "";
  }
};

// switch badge style w.r.t payment status
export function getBadgeStyle(status: string): BadgeVariant {
  const mappedStatus = mappingPaymentStatus(status);
  switch (mappedStatus) {
    case PAID:
    case REFUNDED:
      return "success";
    case SCHEDULED:
    case PENDING:
      return "warning";
    case FAILED:
      return "error";
    case CANCELLED:
      return "light";
    default:
      return "primary";
  }
}

export function getPaymentTotalClass(status: string): string {
  const mappedStatus = mappingPaymentStatus(status);
  // need to revist with design
  switch (mappedStatus) {
    case PAID:
      return "payment-receipt-payment-total-container success";
    case SCHEDULED:
      return "payment-receipt-payment-total-container scheduled";
    case PENDING:
      return "payment-receipt-payment-total-container pending";
    default:
      return "payment-receipt-payment-total-container";
  }
}

export function getPaymentTotalLabelClass(status: string): string {
  const mappedStatus = mappingPaymentStatus(status);
  switch (mappedStatus) {
    case PAID:
      return "payment-receipt-payment-total-label successText";
    case SCHEDULED:
      return "payment-receipt-payment-total-label scheduledText";
    case PENDING:
      return "payment-receipt-payment-total-label pendingText";
    default:
      return "payment-receipt-payment-total-label scheduledText";
  }
}

export function getPaymentTotalValueClass(status: string): string {
  const mappedStatus = mappingPaymentStatus(status);
  switch (mappedStatus) {
    case PAID:
      return "payment-receipt-payment-total-value successText";
    case SCHEDULED:
      return "payment-receipt-payment-total-value scheduledText";
    case PENDING:
      return "payment-receipt-payment-total-value pendingText";
    default:
      return "payment-receipt-payment-total-value scheduledText";
  }
}

export const paymentMethodLabel = (paymentMethod: string) => {
  switch (paymentMethod) {
    case FundingOption.ApplePay:
      return (
        <img
          src={apple_pay_mark}
          alt="Apple Pay"
          style={{ height: "30px" }}
        ></img>
      );
    case FundingOption.GooglePay:
      return (
        <img
          src={google_pay_mark}
          alt="Google Pay"
          style={{ height: "30px" }}
        ></img>
      );
    case FundingOption.PayPal:
      return <img src={paypal_mark} alt="PayPal"></img>;
    default:
      return paymentMethod;
  }
};
export function mapUiLanguageToSessionLanguage(language: string) {
  return language.slice(0, 2) as SessionSupportedLanguages;
}

export function mapSessionLanguageToUiLanguage(language: string) {
  const lang = language.toLowerCase();
  switch (lang) {
    case "en":
      return "en-US";
    case "es":
      return "es-US";
    case "fr":
      return "fr-CA";
    default:
      return "en-US";
  }
}

export function getErrorMessage(error: any) {
  let errorMessageValue: string | null = null;
  let errorMessageType: string | null = null;
  let errorMessageCode: string | null = null;

  if (error) {
    if (error.response) {
      if (
        error.response?.data.errors &&
        error.response.data.errors.length > 0
      ) {
        if (error.response.data.errors[0].code && error.response.data.errors[0].target) {
          errorMessageCode = error.response.data.errors[0].code
          errorMessageType = error.response.data.errors[0].target.type;
          errorMessageValue = error.response.data.errors[0].target.name;
        } else if (error.response.data.errors[0].code) {
          errorMessageCode = error.response.data.errors[0].code
          errorMessageType = error.response.data.errors[0].code;
          errorMessageValue = error.response.data.errors[0].message;
        } else {
          errorMessageValue = error.message;
        }
      }
    } else if (error.request) {
      errorMessageValue = "";
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessageValue = error.message;
    }
  } else if (error instanceof Error) {
    errorMessageValue = error.message;
  } else if (typeof error === "string") {
    errorMessageValue = error;
  } else {
    errorMessageValue = "";
  }

  return { errorMessageValue, errorMessageType, errorMessageCode };
}

export const millisToMinutesAndSeconds = (millis: number) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = +((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

export const isValidUSZip = (zipCode: string) => {
  return /^\d{5}(-\d{4})?$/.test(zipCode);
}

export const phoneFormat = (inputPhone: string) => {

  if (_.isEmpty(inputPhone)) return "";
  return inputPhone
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}