import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo,
} from '@fortawesome/pro-solid-svg-icons';
import { TextButton } from 'kubra-ux-forge';

const isMobile = () => window.innerWidth <= 768;
export const mobile = isMobile();
export interface WelcomeMessageBannerProps {
    messageLabel: string;
    linkText?: string;
    onClick: () => void;
}

export const WelcomeMessageBanner = ({
    messageLabel,
    linkText,
    onClick,
}: WelcomeMessageBannerProps): JSX.Element => {

    return (

        <div className='welcome-banner-container'>
            <div className='welcome-banner-icon-container'>
                <div className='welcome-banner-icon'> 
                <FontAwesomeIcon icon={faCircleInfo} data-cy="welcome-banner-icon" data-testid="welcome-banner-icon" />
                </div>
            </div>
            <div className='welcome-banner-message-container'>
                <div className='welcome-banner-message'>{messageLabel}</div>
            </div>
            <div className='welcome-banner-link'>
                <TextButton hoverStyle='underline' onClick={onClick} >{linkText}</TextButton>
            </div>
        </div>


    );

};