import React, { useState, useEffect } from "react";
import { BarcodeView } from "../barcode-view";
import { EmailView } from "../email-view";
import { SMSView } from "../sms-view";
import { Banner, IconButton, TextButton } from "kubra-ux-forge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import {
  faArrowDownToLine,
  faLocationDot,
} from "@fortawesome/pro-solid-svg-icons";
import { useRetailer } from "hooks";
import { EMPTY, ERROR } from "constants/Status";
import { getRetailers, getRetailersLoadStatus } from "selectors";
import { useSelector } from "react-redux";
import { getBarcode } from "api/IamApi/entities/ezpay";
import { GoogleWalletButton } from "components/ezpay/common/google-wallet-button";
import { AppleWalletButton } from "components/ezpay/common/apple-wallet-button";
import html2canvas from "html2canvas";
import { IBiller } from "types/biller";
import { IRetailer } from "types/retailer";
import { IAccount } from "types/account";
import { useTranslation } from "react-i18next";
import { CASH_MODAL } from "constants/i18n-namespaces";
import { RetailerView } from "../retailer-view";
import { account } from "utilities/preload-state";


export interface ICashModalProps {
  biller: IBiller;
  accountNumber: string;
  billerLogo: string;
  billerConfiguration: { serviceFee: number };
  userAccount: IAccount;
  flowCallback: any;
  realm: string
}

export const CashModal = (props: ICashModalProps) => {
  const { biller, accountNumber, billerLogo, billerConfiguration, userAccount, flowCallback, realm } = props;
  const { t } = useTranslation(CASH_MODAL);
  const [isRetailerView, setIsRetailerView] = useState(false);
  const [barcodeView, setBarcodeView] = useState(true);
  const [emailView, setEmailView] = useState(false);
  const [smsView, setSmsView] = useState(false);
  const loadRetailerStatus = useSelector(getRetailersLoadStatus);
  const { loadRetailers } = useRetailer();
  const [barcodeText, setBarcodeText] = useState("init");
  const [barcodeLoadStatus, setBarcodeLoadStatus] = useState("loading");
  const [retailerSlice, setRetailerSlice] = useState<IRetailer[]>();
  const [fullRetailers, setFullRetailers] = useState<IRetailer[]>();
  const retailers = useSelector(getRetailers);

  // detect apple device
  const isAppleDevice = /Mac|iPhone|iPad|iPod/.test(navigator.userAgent);

  const setView = (method: string) => {
    if (method === "EmailView") {
      setBarcodeView(false);
      setEmailView(true);
      setSmsView(false);
    } else if (method === "SMSView") {
      setBarcodeView(false);
      setEmailView(false);
      setSmsView(true);
    } else {
      setBarcodeView(true);
      setEmailView(false);
      setSmsView(false);
    }
  };

  const setRetailerFlow = (
    isRetailer: boolean | ((prevState: boolean) => boolean)
  ) => {
    setIsRetailerView(isRetailer);
    if (isRetailer) {
      flowCallback("RetailerModal");
    }
    else (flowCallback("CashModal"));
  };

  const downloadBarcode = () => {
    // @ts-ignore
    html2canvas(document.getElementById("cashmodalbarcode"), {
      useCORS: true,
      onclone: function (doc) {
        const hiddenDiv = doc.getElementById("cashmodalbarcode");
        // @ts-ignore
        hiddenDiv.style.display = "block";
      },
    })
      .then((canvas) => {
        const image = canvas.toDataURL("image/png", 1.0);
        return image;
      })
      .then((image) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = image;
        downloadLink.download =
          biller.billerName + " Payment Barcode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  };

  useEffect(() => {
    if (loadRetailerStatus === EMPTY) {
      loadRetailers(biller.clientId, account.realm);
    } else if (loadRetailerStatus === ERROR) {
      console.log("Error retrieving retailers for RCP");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadRetailerStatus,
    biller.clientId,
  ]);

  useEffect(() => {
    if (retailers && biller.clientId) {
      getBarcode(biller.clientId, account.realm)
        .then((res) => {
          setBarcodeText(res.toString());
          setBarcodeLoadStatus("loaded");
        })
        .catch((err) => {
          setBarcodeLoadStatus("error");
        });
      if (retailerSlice === undefined || retailerSlice.length === 0) {
        setFullRetailers(retailers);
        if (retailers.length > 3) {
          setRetailerSlice(
            retailers?.filter((retailer) => {
              return retailer.enabled === true && retailer.preferred === true;
            })
              .sort((a, b) => a.orderNo - b.orderNo)
              .slice(0, 3)
          );

          setFullRetailers(
            retailers?.filter((retailer) => {
              return retailer.enabled === true;
            })
              .sort((a, b) => a.orderNo - b.orderNo)
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biller.clientId, retailers])

  return (
    <div>
      {!isRetailerView && barcodeLoadStatus == "error" && (
        <div className="barcode-error-container">
          <Banner
            messageLabel={`${t("barcode-generation-error")}`}
            variant="error"
            withIcon
          />
        </div>
      )}
      {!isRetailerView && (
        <div className="cash-modal">
          {barcodeLoadStatus !== "error" && (
            <div className="cash-primary-display">
              {barcodeView && (
                <div data-testid="barcode-view">
                  <BarcodeView
                    billerLogo={billerLogo}
                    billerName={biller.billerName}
                    accountNumber={accountNumber}
                    barcodeText={barcodeText}
                    serviceFee={billerConfiguration.serviceFee}
                  /></div>
              )}
              {emailView && (
                <EmailView
                  cancelHandler={setView}
                />
              )}
              {smsView && (
                <SMSView
                  billerName={biller.billerName}
                  cancelHandler={setView}
                  accountNumber={accountNumber}
                  barcodeText={barcodeText}
                />
              )}
            </div>
          )}
          <div></div>
          {barcodeLoadStatus !== "error" && (
            <div className="cash-method">
              <div className="content-row">
                <div className="save-barcode-container-title">
                  {t("save-barcode-label")}
                </div>
              </div>
              <div className="save-barcode-icons-container">
                <IconButton
                  icon={<FontAwesomeIcon icon={faEnvelope} />}
                  label={`${t("get-barcode-email-label")}`}
                  onClick={() => {
                    setView("EmailView");
                  }}
                  className="icon-button"
                  title={`${t("get-barcode-email-button-title")}`}
                  dataCy="icon-button-email-view"
                />
                <IconButton
                  icon={<FontAwesomeIcon icon={faCommentDots} />}
                  label={`${t("get-barcode-sms-label")}`}
                  onClick={() => {
                    setView("SMSView");
                  }}
                  className="icon-button"
                  title={`${t("get-barcode-sms-button-title")}`}
                  dataCy="icon-button-sms-view"
                />
                <IconButton
                  icon={<FontAwesomeIcon icon={faArrowDownToLine} />}
                  label={`${t("download-barcode-label")}`}
                  onClick={() => downloadBarcode()}
                  className="icon-button"
                  title={`${t("download-barcode-button-title")}`}
                />
                <GoogleWalletButton
                  biller={biller}
                  accountNumber={accountNumber}
                  barcodeText={barcodeText}
                  realm={realm}
                />

                {isAppleDevice ? (
                  <AppleWalletButton
                    href="#"
                    biller={biller}
                    accountNumber={accountNumber}
                    barcodeText={barcodeText}
                    realm={realm}
                  />
                ) : null}
              </div>
            </div>
          )}
          <div className="cash-retailers">
            <div className="content-row">
              <div className="save-barcode-container-title">
                {retailers !== undefined && retailers.some(item => item.preferred) && t("pay-with-retailers-label")}
              </div>
              <div></div>
            </div>
            <div
              className="retailer-logos"
              title={`${t("retailer-logos-title")}`}
            >
              {retailerSlice &&
                retailerSlice.map((retailer) => (
                  <img
                    key={retailer.id}
                    src={retailer.logo}
                    alt={retailer.name}
                    className="retailer-logo-img"
                  />
                ))}
            </div>
            <div className="content-row">
              <TextButton
                icon={<FontAwesomeIcon icon={faLocationDot} />}
                onClick={() => setRetailerFlow(true)}
                className="text-button"
                data-testid="viewRetailerButton"
                dataCy="button-find-a-retailer"
              >
                {t("find-a-retailer-label")}
              </TextButton>
            </div>
          </div>
        </div>
      )}
      {isRetailerView && (
        <div>
          <div className="retail-view">
            <RetailerView
              defaultRetailerId="0"
              defaultZipCode={account.validation?.mailingAddress?.postalCode ?? ""}
              mapMode={false}
              retailers={fullRetailers}
            />
          </div>
        </div>
      )}
      <div
        id="cashmodalbarcode"
        className="barcode-hidden"
        aria-hidden="true"
        title="hidden barcode"
      >
        <div className="cash-primary-display">
          <BarcodeView
            billerName={biller.billerName}
            billerLogo={billerLogo}
            accountNumber={accountNumber}
            barcodeText={barcodeText}
            serviceFee={billerConfiguration.serviceFee}
          />
        </div>
      </div>
    </div>
  );
};
export default CashModal;