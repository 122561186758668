import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EMPTY } from "constants/Status";
import { getBillerConfiguration } from "api/IamApi/entities/rcp";
import { useBiller, useRetailer } from "hooks";
import {
  getRetailers,
  getRetailersLoadStatus,
  getBiller,
  getBillerLoadStatus,
} from "selectors";
import { IRetailer } from "types/retailer";
import { IImageResourcesProperties } from "types/biller";
import { useTranslation } from "react-i18next";
import { PAYSLIP_NAMESPACE } from "constants/i18n-namespaces";
import { BarcodeView } from "components/ezpay/secure/common/retail-cash-flow/barcode-view";

export const PaySlip = () => {
  const loadRetailerStatus = useSelector(getRetailersLoadStatus);
  const { loadRetailers } = useRetailer();
  const [retailerSlice, setRetailerSlice] = useState<IRetailer[]>();
  const [billerConfiguration, setBillerConfiguration] = useState({});
  const queryParams = new URLSearchParams(window.location.search);
  const queryAccountNumber = queryParams.get("invoiceNumber");
  const queryBarcodeText = queryParams.get("barcodeText");
  const loadBillerStatus = useSelector(getBillerLoadStatus);
  const { loadBiller } = useBiller();
  const retailers = useSelector(getRetailers);
  const { t } = useTranslation(PAYSLIP_NAMESPACE);

  const biller = useSelector(getBiller);

  useEffect(() => {
    if (loadBillerStatus === EMPTY) {
      loadBiller();
    }

    if (retailerSlice === undefined || retailerSlice.length === 0) {
      if (retailers !== undefined && retailers.length > 3) {
        setRetailerSlice(
          retailers
            .filter((retailer) => {
              return retailer.enabled === true && retailer.preferred === true;
            })
            .sort((a, b) => a.orderNo - b.orderNo)
            .slice(0, 3)
        );
      }
    }
  }, [loadBillerStatus, loadBiller, retailerSlice, retailers]);

  if (biller === null || biller === undefined) return <div></div>;

  const billerLogoUrl =
    (
      biller?.properties?.find(({ name }) => name === "ImageResources")
        ?.properties as IImageResourcesProperties
    ).logoURL ?? "";

  if (
    billerConfiguration === null ||
    billerConfiguration === undefined ||
    Object.keys(billerConfiguration).length == 0
  ) {
    //get biller cash configuration
    if (biller.clientId !== "") {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const axiosResponse = getBillerConfiguration(
        biller.clientId,
        biller.realm
      );
      axiosResponse.then((res) => {
        if (res !== undefined) {
          setBillerConfiguration(res);
        }
      });
    }
  }

  if (loadRetailerStatus === EMPTY) {
    if (biller.clientId !== "") {
      loadRetailers(biller.clientId, biller.realm);
    }
  }

  let retailerSliceFilled;
  if (retailerSlice !== undefined && retailerSlice.length > 0) {
    retailerSliceFilled = true;
  } else {
    retailerSliceFilled = false;
  }

  return (
    <div className="modal-frame-help">
      <div className="cash-view">
        <div className="cash-primary-display" data-testid="payslip-barcode">
          {/* @ts-ignore */}
          <BarcodeView billerLogo={billerLogoUrl} accountNumber={queryAccountNumber ?? ""} barcodeText={queryBarcodeText} serviceFee={billerConfiguration.serviceFee} />
        </div>
        <div className="cash-retailers">
          <div className="content-row">
            <div className="left-text-16">{t("how-to-pay")}</div>
            <div></div>
          </div>
          <div className="retailer-logos">
            {/* @ts-ignore */}
            {retailerSliceFilled &&
              retailerSlice !== undefined &&
              retailerSlice.map((retailer) => (
                <img
                  key={retailer.id}
                  src={retailer.logo}
                  alt={retailer.name}
                  className="retailer-logo-img"
                />
              ))}
          </div>
          <div className="content-row"></div>
        </div>
      </div>
    </div>
  );
};
export default PaySlip;