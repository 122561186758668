import { AxiosResponse } from "axios";
import routes from "../routing";
import { sendGetRequest, sendPostRequest } from "../lib";
import { IStoreLocation } from "types/store-location";
import { IRetailer } from "types/retailer";
import { IBillerConfiguration } from "types/billerConfiguration";

export const getLocationByGeo = (
  latitude: string,
  longitude: string,
  radius: number,
  limit: number,
  realm: string,
  clientId: string
): Promise<IStoreLocation[]> => {
  return sendGetRequest(
    routes.rcp.getLocationByGeo(latitude, longitude, radius, limit),
    {
      headers: {
        "Kubra-Realm": realm,
        "Kubra-ClientId": clientId
      },
    }
  );
};

export const getLocationByZip = (
  zipcode: string,
  limit: number,
  realm: string,
  clientId: string
): Promise<IStoreLocation[]> => {
  return sendGetRequest(routes.rcp.getLocationByZip(zipcode, limit), {
    headers: {
      "Kubra-Realm": realm,
      "Kubra-ClientId": clientId
    },
  });
};

export const getRetailers = (
  clientId: string,
  realm: string
): Promise<AxiosResponse<IRetailer>> =>
  sendGetRequest(routes.rcp.getRetailers(), {
    headers: {
      "Kubra-ClientId": clientId,
      "Kubra-Realm": realm,
    },
  });

export const getLogo = (
  imageName: string,
  realm: string
): Promise<AxiosResponse<string>> =>
  sendGetRequest(routes.rcp.getLogo(imageName), {
    headers: { "Kubra-Realm": realm },
  });

export const postBarcode = (
  clientId: string,
  barcodeRequest: unknown,
  realm: string
): Promise<AxiosResponse> => {
  return sendPostRequest(barcodeRequest, routes.rcp.postBarcode(), {
    headers: {
      "Kubra-ClientId": clientId,
      "Kubra-Realm": realm,
    },
  });
};

export const postApplePass = (
  clientId: string,
  applePassRequest: unknown,
  realm: string
): Promise<AxiosResponse> => {
  return sendPostRequest(applePassRequest, routes.rcp.postApplePass(), {
    headers: {
      "Kubra-ClientId": clientId,
      "Kubra-Realm": realm,
    },
  });
};

export const postGooglePass = (
  clientId: string,
  googlePassRequest: unknown,
  realm: string
): Promise<AxiosResponse> => {
  return sendPostRequest(googlePassRequest, routes.rcp.postGooglePass(), {
    headers: {
      "Kubra-ClientId": clientId,
      "Kubra-Realm": realm,
    },
  });
};

export const getBillerConfiguration = (
  clientId: string,
  realm: string
): Promise<AxiosResponse<IBillerConfiguration>> =>
  sendGetRequest(routes.rcp.getBillerConfiguration(), {
    headers: {
      "Kubra-ClientId": clientId,
      "Kubra-Realm": realm,
    },
  });

export default {
  getLocationByGeo,
  getLogo,
  getLocationByZip,
  postGooglePass,
  postApplePass,
  postBarcode,
  getRetailers,
  getBillerConfiguration,
};
