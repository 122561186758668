import React, { useState } from "react";
import { InputField, LoadingButton, TextButton } from "kubra-ux-forge";
import { postEmail } from "api/IamApi/entities/ezpay";
import triangleWarning from "assets/icons/triangle_exclamation.svg";
import { getBiller } from "selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { COMMON_NAMESPACE, EMAIL_VIEW } from "constants/i18n-namespaces";
import { PLATFORM_NAMESPACE } from "kubra-labels";

export interface IEmailViewProps {
  cancelHandler: (arg0: string) => void;
}

export const EmailView = (props: IEmailViewProps) => {
  const { cancelHandler } = props;
  const { t } = useTranslation(EMAIL_VIEW);
  const [success, setSuccess] = useState<string>("text");
  const [showInvalidEmail, setShowInvalidEmail] = useState(false);
  const [showEmptyEmail, setShowEmptyEmail] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const biller = useSelector(getBiller);

  const isEmail = (val: string) => {
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regEmail.test(val);
  };

  const updateEmailAddress = (e: string) => {
    setShowInvalidEmail(false);
    setShowEmptyEmail(false);
    setShowErrorMessage(false);
    setErrorState(false);
    setEmailAddress(e);
  };

  const sendEmail = () => {
    if (emailAddress === undefined) {
      setShowEmptyEmail(true);
      setErrorState(true);
    } else if (emailAddress !== undefined && emailAddress.length === 0) {
      setShowEmptyEmail(true);
      setErrorState(true);
    } else {
      if (isEmail(emailAddress)) {
        setSuccess("loading");
        if (biller?.instanceId != null && biller.instanceId != undefined) {
          const axiosResponse = postEmail(
            biller?.instanceId,
            biller?.clientId,
            emailAddress
          );
          axiosResponse.then((res) => {
            if (res.status === 200) {
              setSuccess("success");
            } else {
              setSuccess("text");
              setShowErrorMessage(true);
            }
          });
        } else {
          setSuccess("text");
          setShowErrorMessage(true);
        }
      } else {
        setShowInvalidEmail(true);
      }
    }
  };

  let sentSuccess = false;
  if (success === "success") {
    sentSuccess = true;
  }

  return (
    <div data-cy="email-view">
      <div className="content-row">
        <InputField
          defaultValue=""
          errorText=""
          helperText=""
          label={`${t("email-label")}`}
          onChange={(e: { target: { value: string } }) =>
            updateEmailAddress(e.target.value)
          }
          hasError={errorState}
          title={`${t("email-title")}`}
          dataCy="title-email"
        />
        {showInvalidEmail && (
          <div>
            <img
              src={triangleWarning}
              alt="error"
              className="floatLeft"
              aria-hidden={true}
            />
            <div className="errorText">{t("email-invalid")}</div>
          </div>
        )}
        {showEmptyEmail && (
          <div>
            <img
              src={triangleWarning}
              alt="error"
              className="floatLeft"
              aria-hidden={true}
            />
            <div className="errorText">{t("email-required")}</div>
          </div>
        )}
        {showErrorMessage && (
          <div>
            <img src={triangleWarning} alt="error" className="floatLeft" />
            <div className="errorText">{t("error-sending-email")}</div>
          </div>
        )}
      </div>
      <div className="content-row">
        <div className="content-row-left-button">
          <TextButton
            onClick={() => cancelHandler("BarcodeView")}
            className="cancel-button"
            dataCy="button-cancel"
          >
            {t("cancelLabel", { ns: PLATFORM_NAMESPACE })}
          </TextButton>
        </div>
        <div className="content-row-right-button">
         
          <LoadingButton className="send-button" onClick={() => { sendEmail(); }} loadingState={success} variant="text"
            title="send button" dataCy="button-send"
          >
            {t("send", { ns: COMMON_NAMESPACE })}
          </LoadingButton>
        </div>
      </div>
      {sentSuccess && <div title="sent success"></div>}
    </div>
  );
}
