import React, { useState } from "react";
import {
  BankAccount,
  Card,
  getCardNetworkByShortCode,
  translateBankAccountType
} from "constants/FundingOption";
import {
  COMMON_NAMESPACE,
  MANAGE_PAYMENT_METHODS_MODAL,
  BALANCE_NAMESPACE,
} from "constants/i18n-namespaces";
import useWallet from "hooks/useWallet";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import Sheet from "react-modal-sheet";
import XButton from "assets/icons/x_button.svg";
import { CreditCardLogo, PaymentMethodRow } from "kubra-ux-forge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBank } from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import { CardType } from "kubra-ux-forge/dist/foundation/CreditCardLogos/CreditCardLogo";
import { isExpiryCardDateValid } from "../credit-debit-modal";
import { TermsModal } from "components/ezpay/common/terms-modal";
import IamApi from "api/IamApi";
import { useSelector } from "react-redux";
import { getBiller } from "selectors";
import { customStyles } from "constants/customStyles";

export interface IManagePaymentMethodsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ManagePaymentMethodsModal = (props: IManagePaymentMethodsModalProps) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation([MANAGE_PAYMENT_METHODS_MODAL, BALANCE_NAMESPACE]);
  const [termsModalDisplay, setTermsModalDisplay] = useState(false);
  const { getWalletItems, loadWalletItems } = useWallet();
  const biller = useSelector(getBiller);
  const walletItems = getWalletItems();
  const bankAccounts = walletItems.filter((_) => _.fundingOption === BankAccount);
  const cards = walletItems.filter((_) => _.fundingOption === Card);
  const deleteWallet = async (clientId: string, fundingSourceId: string) => {
    await IamApi.ezpay.deleteWalletItem(clientId, fundingSourceId);
    // refresh wallet state after 2s to avoid interuptting delete animation
    setTimeout(() => {
      loadWalletItems(clientId);
    }, 2000);
  };
 
  

  if (isMobile) {
    return (
      <>
      <Sheet
        isOpen={isOpen}
        onClose={onClose}
        detent="content-height"
        disableDrag={true}
      >
        <Sheet.Container>
          <Sheet.Header>
            <div className="react-modal-sheet-header">
              <div className="help-modal-header-text">{t("select-method")}</div>
              <button
                className="modal-close"
                onClick={onClose}
                aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                data-cy="modal-close-x-help"
              >
                <img
                  src={XButton}
                  alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                  className="modal-close-x"
                />
              </button>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <div className="wallet-frame">
              {bankAccounts.map((bankAccount) => {
                return (
                  <PaymentMethodRow
                    key={bankAccount.fundingSourceId}
                    icon={<FontAwesomeIcon icon={faBank}></FontAwesomeIcon>}
                    label={`${translateBankAccountType(bankAccount.presentableMOPCode, t)} ${bankAccount.maskedNumber
                      }`}
                    onDelete={() =>
                      deleteWallet(biller.clientId, bankAccount.fundingSourceId)
                    }
                    /* @ts-ignore */
                    className="payment-method-row"
                    variant="default"
                    /* @ts-ignore */
                    dataCy={`payment-mehod-row-${bankAccount.presentableMOPCode} ${bankAccount.maskedNumber}`}
                  />
                );
              })}
              {cards.map((card) => {
                return (
                  <PaymentMethodRow
                    key={card.fundingSourceId}
                    error={!isExpiryCardDateValid(card.expiry)}
                    expiry={card.expiry}
                    icon={
                      <CreditCardLogo
                        cardType={_.lowerCase(getCardNetworkByShortCode(card.presentableMOPCode)) as CardType}
                      />
                    }
                    label={`${getCardNetworkByShortCode(card.presentableMOPCode)} ${card.maskedNumber}`}
                    onDelete={() =>
                      deleteWallet(biller.clientId, card.fundingSourceId)
                    }
                    /* @ts-ignore */
                    className="payment-method-row"
                    variant="default"
                    /* @ts-ignore */
                    dataCy={`payment-mehod-row-${getCardNetworkByShortCode(card.presentableMOPCode)} ${card.maskedNumber}`}
                  />
                );
              })}
              <div className="wallet-info-credential">
                <div>
                  {t("store-wallet-credential")}
                  <button
                    className="link-button"
                    onClick={(e) =>{ e.preventDefault(); setTermsModalDisplay(true)}}
                  >
                    {t("terms-of-use")}
                  </button>
                  {t("store-wallet-credential-continue")}
                </div>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      <TermsModal
        isOpen={termsModalDisplay}
        isMobile={true}
        onClose={() => setTermsModalDisplay(false)}
        header="termsOfUse"
      />
      </>
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        className="modal-frame-help"
        shouldCloseOnOverlayClick={false}
        onRequestClose={onClose}
        style={customStyles}
      >
        <div className="modal-header">
          <div className="help-modal-header-text">{t("select-method")}</div>
          <button
            className="modal-close"
            onClick={onClose}
            aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
            data-cy="modal-close-x-help"
          >
            <img
              src={XButton}
              alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
              className="modal-close-x"
            />
          </button>
        </div>
        <div className="wallet-frame">
          {bankAccounts.map((bankAccount) => {
            return (
              <PaymentMethodRow
                key={bankAccount.fundingSourceId}
                icon={<FontAwesomeIcon icon={faBank}></FontAwesomeIcon>}
                label={`${translateBankAccountType(bankAccount.presentableMOPCode, t)} ${bankAccount.maskedNumber
                }`}
                onDelete={() =>
                  deleteWallet(biller.clientId, bankAccount.fundingSourceId)
                }
                /* @ts-ignore */
                className="payment-method-row"
                variant="default"
                /* @ts-ignore */
                dataCy={`payment-mehod-row-${bankAccount.presentableMOPCode} ${bankAccount.maskedNumber}`}
              />
            );
          })}
          {cards.map((card) => {
            return (
              <PaymentMethodRow
                key={card.fundingSourceId}
                error={!isExpiryCardDateValid(card.expiry)}
                expiry={card.expiry}
                icon={
                  <CreditCardLogo
                    cardType={_.lowerCase(getCardNetworkByShortCode(card.presentableMOPCode)) as CardType}
                  />
                }
                label={`${getCardNetworkByShortCode(card.presentableMOPCode)} ${card.maskedNumber}`}
                onDelete={() =>
                  deleteWallet(biller.clientId, card.fundingSourceId)
                }
                /* @ts-ignore */
                className="payment-method-row"
                variant="default"
                /* @ts-ignore */
                dataCy={`payment-mehod-row-${getCardNetworkByShortCode(card.presentableMOPCode)} ${card.maskedNumber}`}
              />
            );
          })}
          <div className="wallet-info-credential">
            <div>
              {t("store-wallet-credential")}
              <button
                data-testid="terms-link-button"
                className="link-button"
                onClick={(e) =>{ e.preventDefault(); setTermsModalDisplay(true)}}
              >
                {t("terms-of-use")}
              </button>
              {t("store-wallet-credential-continue")}
            </div>
          </div>
        </div>
      </Modal>
      <TermsModal
        isOpen={termsModalDisplay}
        isMobile={isMobile}
        onClose={() => setTermsModalDisplay(false)}
        header="termsOfUse"
      />
    </>
  );
};
