import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useForm } from "react-hook-form";
import Sheet from "react-modal-sheet";
import Modal from "react-modal";
import { BillModal } from "components/ezpay/common/bill-modal";
import { useSelector } from "react-redux";
import { getBillerLoadStatus } from "selectors";
import { DONE } from "constants/Status";
import triangleWarning from "assets/icons/triangle_exclamation.svg";
import XButton from "assets/icons/x_button.svg";
import SubmitButton from "components/ezpay/common/submit-button/submit-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import { InputField, TextButton } from "kubra-ux-forge";
import { ISession } from "types/session";
import { useTranslation } from "react-i18next";
import {
  COMMON_NAMESPACE,
  VALIDATE_NAMESPACE,
} from "constants/i18n-namespaces";
import IamApi from "api/IamApi";
import { mapUiLanguageToSessionLanguage, getDomain} from "utilities/helper";
import { AnalyticsEventType, useTracking } from "kubra-ui-lib-mfe";
import type { AnalyticsEvent } from "kubra-ui-lib-mfe";
import { customStyles } from "constants/customStyles";
import { IValidationField } from "types/billerConfigResponse";
import { WelcomeModal } from "components/ezpay/common/welcome-modal/welcome-modal";
import { WelcomeMessageBanner } from "components/ezpay/common/welcome-message-banner/welcome-message-banner";

export interface ValidationFormProps {
  billerId: string;
  validationFields: IValidationField[];
  instanceId: string;
  isMobile: any;
  billImage: string;
  fingerprint: string;
  accountPlaceholder: string;
  vcodePlaceholder: string;
  isBillerMessageEnabled: boolean;
  billerMessage: string;
  billerName: string;
}

export const ValidationForm = (props: ValidationFormProps) => {
  const {
    billerId,
    validationFields,
    instanceId,
    isMobile,
    billImage,
    fingerprint,
    accountPlaceholder,
    vcodePlaceholder,
    isBillerMessageEnabled,
    billerMessage,
    billerName
  } = props;

  const { trackEvent } = useTracking<Partial<AnalyticsEvent>>();

  const domain = getDomain();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useOutletContext<ISession>();
  const { t, i18n } = useTranslation(VALIDATE_NAMESPACE);
  const validationErrorMessage = t("validation-error", {
    billedId: billerId,
  });

 // Clear error message when user changes the language
  useEffect(() => {
    setErrorMessage("");
  }, [i18n.language])
   

  const validateAccount = async (
    instanceId: string,
    submissionFields: unknown,
    accNum: string
  ) => {
    setLoading(true);
    try {
      const validationResponse = await IamApi.ezpay.postValidation(
        instanceId,
        mapUiLanguageToSessionLanguage(i18n.language),
        domain,
        fingerprint,
        navigator.userAgent,
        submissionFields
      );
      trackEvent({
        type: AnalyticsEventType.userIdentity,
        userId: accNum,
      });

      login({ ...validationResponse.data, fingerprint: fingerprint });
    } catch (error) {
      setErrorMessage(validationErrorMessage);
      setLoading(false);
      trackEvent({
        type: AnalyticsEventType.customEvent,
        event: "login_failure",
        item_id: accNum,
      });
    }
  };

  const onSubmit = (data: { [x: string]: string }) => {
    const submissionFields = {};
    if (validationFields) {
      validationFields.forEach((validationField) => {
        const lowerName = validationField.name.toLowerCase();
        const origName = validationField.name;
        if (lowerName.includes("account")) {
          // @ts-ignore
          submissionFields[origName] = data.accountNumber;
        } else {
          // @ts-ignore
          submissionFields[origName] = data[lowerName];
        }
      });
    }
    validateAccount(instanceId, submissionFields, data.accountNumber);
  };

  const handleAccChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setErrorMessage("");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
    window.setTimeout(RemoveOverflow, 50);
  };

  const [welcomeModalIsOpen, setWelcomeModalIsOpen] = useState(true);

  const setWelcomeModalIsOpenToTrue = () => {
    setWelcomeModalIsOpen(true);
  };

  const setWelcomeModalIsOpenToFalse = () => {
    setWelcomeModalIsOpen(false);
    window.setTimeout(RemoveOverflow, 50);
  };

  function RemoveOverflow() {
    document.body.style.removeProperty('overflow');
  }

  const getLabelValue = (validationField: IValidationField): string | undefined => {
    const label = validationField.label.find(label => label.language === i18n.language);    
    return label?.value;
  };

  const renderField = (fieldName: string, fieldLabel: string | undefined) => {
    const lowerName = fieldName.toLowerCase();
    if (lowerName.includes("account")) {
      return (
        <div key="accountNumber" className="validate-row">
          <label htmlFor="txtAccountNumber" className="validate-label">
            {fieldLabel}
          </label>
          <InputField
            id="txtAccountNumber"
            data-cy="input-account-number"
            // @ts-ignore
            name="Account Number"
            // @ts-ignore
            onChange={handleAccChange}
            errorText={t("required-field", { fieldLabel })}
            placeholder={accountPlaceholder}
            maxLength="250"
            hasError={errors.accountNumber}
            {...register("accountNumber", { required: true })}
          />
          <TextButton
            onClick={(event) => {
              event.preventDefault();
              setModalIsOpenToTrue();
            }}
            className="account-modal-link"
            data-cy="link-where-to-find-account"
            hoverStyle="underline"
            icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
            type="button"
          >
            <div className="account-modal-link-label">
              {t("where-to-find-account-number")}
            </div>
          </TextButton>
        </div>
      );
    } else {
      return (
        <div key={lowerName} className="validate-row">
          <label htmlFor={`txt${lowerName}`} className="validate-label">
            {fieldLabel}
          </label>
          <InputField
            id={`txt${lowerName}`}
            data-cy={`input-${lowerName}`}
            placeholder={vcodePlaceholder}
            // @ts-ignore
            onChange={(ev) => setValue(fieldName, ev.target.value)}
            hasError={errors[lowerName]}
            errorText={t("required-field", { fieldLabel })}
            {...register(lowerName, { required: true })}
          />
        </div>
      );
    }
  };

  const billerLoadStatus = useSelector(getBillerLoadStatus);
  let billerLoaded = false;
  if (billerLoadStatus === DONE) {
    billerLoaded = true;
  }

  return (
    <>
      {errorMessage && errorMessage.length > 0 && (
        <div className="validate-error">
          <img
            src={triangleWarning}
            alt={`${t("invalid-entry")}`}
            className="validate-error-icon"
          />
          <div>
            <div className="validation-error-header">{t("invalid-entry")}</div>
            <div className="validation-error-message">{errorMessage}</div>
          </div>
        </div>
      )}

          {/* banner for Biller Message*/}
          {(isBillerMessageEnabled && billerMessage != undefined) && (
            <div className="welcome-banner-div">
            <WelcomeMessageBanner messageLabel= {t("welcome-banner-title")} linkText= {`${t("view", { ns: COMMON_NAMESPACE })}`}  onClick={setWelcomeModalIsOpenToTrue}></WelcomeMessageBanner>
            </div>
      )}
      {(isBillerMessageEnabled && billerMessage != undefined) && (
        <WelcomeModal
          headingText={t("biller-message-label", { billerName: billerName, })} 
          open={welcomeModalIsOpen} 
          onClose={setWelcomeModalIsOpenToFalse}
          infoText={billerMessage}
          closeIcon = {isMobile}
          closeButtonLabel={`${t("close", { ns: COMMON_NAMESPACE })}`}
        />
        
         )}
      <div className="validate-form">

   

        {isMobile && (
          <Sheet
            isOpen={modalIsOpen}
            onClose={setModalIsOpenToFalse}
            detent="content-height"
            disableDrag={true}
          >
            <Sheet.Container>
              <Sheet.Header>
                <div className="react-modal-sheet-header">
                  <div className="help-modal-header-text">
                    {t("information")}
                  </div>
                  <button
                    className="modal-close"
                    onClick={setModalIsOpenToFalse}
                    data-cy="modal-close-x-information"
                  >
                    <img
                      src={XButton}
                      alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                      className="modal-close-x"
                    />
                  </button>
                </div>
              </Sheet.Header>
              <Sheet.Content>
                <BillModal billImage={billImage} />
              </Sheet.Content>
            </Sheet.Container>
            {/* @ts-ignore */}
            <Sheet.Backdrop onClick={setModalIsOpenToFalse} />
          </Sheet>
        )}

        {!isMobile && (
          /* @ts-ignore */
          <Modal
            isOpen={modalIsOpen}
            ariaHideApp={false}
            className="modal-frame-help"
            shouldCloseOnOverlayClick={true}
            onRequestClose={setModalIsOpenToFalse}
            style={customStyles}
          >
            <div className="modal-header">
              <div className="help-modal-header-text">{t("information")}</div>
              <button
                className="modal-close"
                onClick={setModalIsOpenToFalse}
                aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}}`}
                data-cy="modal-close-x-contact-toc"
              >
                <img
                  src={XButton}
                  alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}}`}
                  className="modal-close-x"
                />
              </button>
            </div>
            <BillModal billImage={billImage} />
          </Modal>
        )}

        {/* @ts-ignore */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="validate-row">
            <div className="center-text">{t("validate-label")}</div>
          </div>

          {billerLoaded && validationFields?.map((validationField) => {
              return renderField(
                validationField.name,
                getLabelValue(validationField)
              );
            })}

          <div className="validate-row" data-cy="button-validate">
            {/* @ts-ignore */}
            <SubmitButton
              buttonText={`${t("continue", { ns: COMMON_NAMESPACE })}`}
              loading={loading}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default ValidationForm;
