import {
  PRIVACY_MODAL_NAMESPACE,
  COMMON_NAMESPACE,
  CONTACT_TOC_FOOTER,
} from "constants/i18n-namespaces";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IBiller, IContactInfoProperties } from "types/biller";
import Modal from "react-modal";
import Sheet from "react-modal-sheet";
import XButton from "assets/icons/x_button.svg";
import { TextButton } from "kubra-ux-forge";
import { customStyles } from "constants/customStyles";
import { useSelector } from "react-redux";
import { getBiller } from "selectors";
import { IExternalLinksProperties } from "types/biller";
import { ContactModal } from "../contact-modal";
import { TermsModal } from "../terms-modal";
import { phoneFormat } from "utilities/helper";
import { PrivacyModal } from "../privacy-modal";

interface ContactAndTocFooterProps {
  biller?: IBiller;
  isMobile: boolean;
}

export const ContactAndTocFooter = ({ biller, isMobile }: ContactAndTocFooterProps) => {
  const { t } = useTranslation(CONTACT_TOC_FOOTER);
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [privacyModalDisplay, setPrivacyModalDisplay] = useState(false);
  const [showTocModal, setShowTocModal] = useState(false);
  const finalBiller = useSelector(getBiller);
  const externalLinks = finalBiller?.properties?.find(
    (element) => element.name === "ExternalLinks"
  )?.properties as IExternalLinksProperties;  

  const termsAndConditionsLink = externalLinks?.termsAndConditions;

  const contactUs = biller?.properties?.find((element) => {
    return element.name === "InstanceConfiguration";
  })?.properties as IContactInfoProperties;

  return (
    <>
      <div className="footer-links-container">
        <div>
          <span className="need-help-text">{t("need-help")}&nbsp;</span>
          <TextButton
            hoverStyle="underline"
            onClick={() => setShowContactUsModal(true)}
            className="contact-us-text"
            data-testid="contact-us-text"
          >
            {t("contact-us")}
          </TextButton>
        </div>
        <div>
          <TextButton
            hoverStyle="underline"
            onClick={() => {
              termsAndConditionsLink
                ? window.open(termsAndConditionsLink, "_blank")
                : setShowTocModal(true);
            }}
            className="terms-text"
            data-testid="term-conditions-text"
          >
            {t("term-and-conditions")}
          </TextButton>
        </div>
      </div>
      {isMobile && (
        <Sheet
          isOpen={showContactUsModal}
          onClose={() => setShowContactUsModal(false)}
          detent="content-height"
          disableDrag={true}
        >
          <Sheet.Container>
            <Sheet.Header>
              <div className="react-modal-sheet-header">
                <div className="help-modal-header-text">
                  {t("contact")} {biller?.billerName ?? t("us")}
                </div>
                <button
                  className="modal-close"
                  onClick={() => setShowContactUsModal(false)}
                  aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}}`}
                  data-cy="modal-close-x-contact-toc"
                >
                  <img
                    src={XButton}
                    alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}}`}
                    className="modal-close-x"
                  />
                </button>
              </div>
            </Sheet.Header>
            <Sheet.Content>
              <ContactModal
                contactPhone={phoneFormat(contactUs?.phone ?? "")}
                contactEmail={contactUs?.email ?? ""}
                contactAddress={contactUs?.street ?? ""}
                contactCity={contactUs?.city ?? ""}
                contactState={contactUs?.state ?? ""}
                contactZip={contactUs?.zipCode ?? ""}
                contactWebsite={contactUs?.website ?? ""}
              />
            </Sheet.Content>
          </Sheet.Container>
          {/*@ts-ignore*/}
          <Sheet.Backdrop onClick={() => setShowContactUsModal(false)} />
        </Sheet>
      )}
      {!isMobile && (
        /* @ts-ignore */
        <Modal
          isOpen={showContactUsModal}
          ariaHideApp={false}
          className="modal-frame-help"
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setShowContactUsModal(false)}
          style={customStyles}
        >
          <div className="modal-header">
            <div className="help-modal-header-text">
              {t("contact")} {biller?.billerName ?? t("us")}
            </div>
            <button
              className="modal-close"
              onClick={() => setShowContactUsModal(false)}
              aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}}`}
              data-cy="modal-close-x-contact-toc"
            >
              <img
                src={XButton}
                alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}}`}
                className="modal-close-x"
              />
            </button>
          </div>

          <ContactModal
            contactPhone={phoneFormat(contactUs?.phone ?? "")}
            contactEmail={contactUs?.email ?? ""}
            contactAddress={contactUs?.street ?? ""}
            contactCity={contactUs?.city ?? ""}
            contactState={contactUs?.state ?? ""}
            contactZip={contactUs?.zipCode ?? ""}
            contactWebsite={contactUs?.website ?? ""}
          />
        </Modal>
      )}     

      <TermsModal
        isOpen={showTocModal}
        isMobile={isMobile}
        onClose={() => setShowTocModal(false)}
      />
    
    </>
  );
};
