import React, { useEffect, useState } from "react";
import {
  InputField,
  Select,
  TextButton,
  Accordion,
  IconButton,
  Radio,
  RadioGroup,
  Button,
} from "kubra-ux-forge";
import { getLocationByGeo, getLocationByZip } from "api/IamApi/entities/rcp";
import { RetailerItem } from "../retailer-item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationArrow,
  faMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { RETAILER_VIEW, COMMON_NAMESPACE } from "constants/i18n-namespaces";
import routing from "routing";
import locationImage from "assets/locationImage.png";
import { SkeletonRow } from "components/skeleton-row";
import { IRetailer } from "types/retailer";
import { MenuOption } from "kubra-ux-forge/dist/elements/MenuList/hooks/useMenuList";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import Sheet from "react-modal-sheet";
import XButton from "assets/icons/x_button.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getNearbyRetailerLocations,
  getSelectedRetailerLocations,
} from "selectors";
import { useBiller, useRetailer } from "hooks";
import styled from "styled-components";
import {
  setNearbyRetailerLocations,
  setSelectedStoreLocations,
} from "actions/retailerLocationActions";
import { ICoords } from "types/coords";
import { isValidUSZip } from "utilities/helper";
import { useSearchParams } from "react-router-dom";
import queryParameter from "utilities/queryParameter";
import { IAccount } from "types/account";

export interface IRetailerViewProps {
  defaultZipCode: string | null;
  defaultRetailerId: string | null;
  mapMode: boolean;
  retailers?: IRetailer[];
  userAccount?: IAccount;
  onZipCodeQueryParametersChange?: (
    zipCode: string | null,
    retailerId: string | null,
    storeId: string | null
  ) => void;
  onCoordinatesQueryParametersChange?: (
    lat: string | null,
    lng: string | null,
    retailerId: string | null,
    storeId: string | null
  ) => void;
}

export const StyledAccordionWrapper = styled.div`
  > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const RetailerView = (props: IRetailerViewProps) => {
  const {
    defaultZipCode,
    defaultRetailerId,
    mapMode,
    userAccount,
    onZipCodeQueryParametersChange,
    onCoordinatesQueryParametersChange,
  } = props;
  const { getBiller } = useBiller();
  // pre-populate the zipCode from account mailing address if possible
  const [zipCode, setZipCode] = useState(defaultZipCode);
  const [searchParams, setSearchParams] = useSearchParams();
  const userLat = searchParams.get(queryParameter.lat);
  const userLng = searchParams.get(queryParameter.lng);
  const [retailerId, setRetailerId] = useState(defaultRetailerId ?? "0");
  const [tempRadioRetailerId, setTempRadioRetailerId] = useState(
    defaultRetailerId ?? "0"
  );
  const [curtLocation, setCurtLocation] = useState<ICoords>({
    lat: userLat ? Number(userLat) : 0,
    lng: userLng ? Number(userLng) : 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [useRealLocation, setUseRealLocation] = useState(
    userLat && userLng ? true : false
  );
  const { t } = useTranslation(RETAILER_VIEW);
  const [showRetailerPopoverList, setShowRetailerPopoverList] = useState(false);
  const [displayMobileLocations, setDisplayMobileLocations] = useState(false);
  const [retailerSheetIsOpen, setRetailerSheetIsOpen] = useState(false);
  const biller = getBiller();
  const dispatch = useDispatch();
  const nearByRetailerLocations = useSelector(getNearbyRetailerLocations);
  const selectedRetailerLocations = useSelector(getSelectedRetailerLocations);
  const { getRetailers } = useRetailer();
  const retailers = getRetailers();
  const retailerViewAllOption: MenuOption = {
    id: "0",
    name: t("view-all"),
  };
  const searchRadiusInMiles = 5;
  const searchResultLimit = 10;

  //handle changing css classes and labels for elements
  const zipClass = mapMode ? "zip-input-map" : "zip-input";
  const filterClass = mapMode ? "retailer-filter-map" : "retailer-filter";
  const accordionHeader = displayMobileLocations
    ? "hide-location-list"
    : "view-location-list";

  const getUserRealLocation = () => {
    setUseRealLocation(true);
    setZipCode("");
    setIsLoading(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setCurtLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        function () {
          console.log("Failed to retrieve geoLocation");
        }
      );
    } else {
      console.log("Browser does not support geoLocation");
    }
  };

  const navigateToMap = () => {
    let path;
    if (useRealLocation) {
      path = zipCode
        ? routing.retailerLocation.index() +
        `?${queryParameter.zipCode}=${zipCode}`
        : routing.retailerLocation.index();
    } else {
      getUserRealLocation();
      path =
        curtLocation.lat !== 0 && curtLocation.lng !== 0
          ? routing.retailerLocation.index() +
          `?${queryParameter.lat}=${curtLocation.lat}&${queryParameter.lng}=${curtLocation.lng}`
          : routing.retailerLocation.index();
    }
    window.open(path, "_blank", "noreferrer");
  };

  const itemNavigateToMap = (storeId: string) => {
    let path;
    if (useRealLocation) {
      path =
        routing.retailerLocation.index() +
        `?${queryParameter.lat}=${curtLocation.lat}&${queryParameter.lng}=${curtLocation.lng}&${queryParameter.retailerId}=${retailerId}&storeId=${storeId}`;
    } else {
      path =
        routing.retailerLocation.index() +
        `?${queryParameter.zipCode}=${zipCode}&${queryParameter.retailerId}=${retailerId}&storeId=${storeId}`;
    }
    window.open(path, "_blank", "noreferrer");
  };

  const getRetailerOptions = () => {
    const retailerSelections: MenuOption[] = [];
    retailerSelections.push(retailerViewAllOption);
    const options: MenuOption[] = retailers.map(
      (retailer) =>
      ({
        id: retailer.id,
        name: retailer.name,
      } as MenuOption)
    );
    retailerSelections.push(...options);
    return retailerSelections;
  };

  const getSelectedRetailerOption = () => {
    if (retailerId !== "0") {
      const selectedRetailer = retailers?.find(
        (retailer) => retailer.id === retailerId
      );
      if (selectedRetailer) {
        return { id: retailerId, name: selectedRetailer.name };
      }
    }
    return retailerViewAllOption;
  };

  const getRetailerSheetContent = () => {
    const retailerRadios = [<Radio key="0" label={t("view-all")} value="0" />];
    if (retailers) {
      const retailerOptions = retailers.map((retailer) => (
        <Radio key={retailer.id} label={retailer.name} value={retailer.id} />
      ));
      retailerRadios.push(...retailerOptions);
    }
    return retailerRadios;
  };

  const handleRetailerChange = (retailerId: string | null) => {
    if (retailerId) {
      if (retailerId === "0") {
        dispatch(setSelectedStoreLocations(nearByRetailerLocations));
      } else {
        const selectedRetailerLocations = nearByRetailerLocations.filter(
          (location) => location.id === retailerId
        );
        if (selectedRetailerLocations) {
          dispatch(setSelectedStoreLocations(selectedRetailerLocations));
        }
        setShowRetailerPopoverList(!showRetailerPopoverList);
      }
      if (useRealLocation) {
        onCoordinatesQueryParametersChange?.(
          curtLocation.lat.toString(),
          curtLocation.lng.toString(),
          retailerId,
          null
        );
      } else {
        onZipCodeQueryParametersChange?.(zipCode, retailerId, null);
      }
      setRetailerId(retailerId);
    }
    if (retailerSheetIsOpen) {
      setRetailerSheetIsOpen(false);
    }
  };

  const handleAccordionChange = () => {
    setDisplayMobileLocations(!displayMobileLocations);
  };

  const getRetailersForDisplay = () => {
    return (
      <div>
        {isLoading && <SkeletonRow />}
        {nearByRetailerLocations.length == 0 && (
          <div className="retailer-empty-view">
            <img
              src={locationImage}
              alt={t("location-alt-text")}
              style={{ height: "150px" }}
            ></img>
            <div className="retailer-info">{t("retailer-empty-info")}</div>
            <div className="retailer-info-or">OR</div>
            <div className="retailer-map-button">
              <TextButton
                onClick={() => navigateToMap()}
                hoverStyle="underline"
                data-testid="view-map-button"
                className="retailer-map-button"
              >
                {t("view-map-label")}
              </TextButton>
            </div>
          </div>
        )}
        <div className="retailer-result">
          <br />
          {t("results-found-label", {
            totalResults: selectedRetailerLocations.length,
          })}
        </div>

        {selectedRetailerLocations.map((location) => (
          <RetailerItem
            onSelectedStoreChange={() => {
              if (useRealLocation) {
                onCoordinatesQueryParametersChange?.(
                  curtLocation.lat.toString(),
                  curtLocation.lng.toString(),
                  retailerId,
                  location.storeId
                );
              } else {
                onZipCodeQueryParametersChange?.(
                  zipCode,
                  retailerId,
                  location.storeId
                );
              }
            }}
            key={location.storeId}
            mapMode={mapMode}
            mapCallback={() => itemNavigateToMap(location.storeId)}
            storeLocation={location}
          />
        ))}
      </div>
    );
  };

  //get geolocation
  useEffect(() => {
    if (!biller) return;

    if (zipCode && isValidUSZip(zipCode)) {
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      getLocationByZip(zipCode, 10, biller?.realm, biller?.clientId).then((result) => {
        /* @ts-ignore */
        if (result && result.length > 0) {
          /* @ts-ignore */
          dispatch(setNearbyRetailerLocations(result));
          dispatch(setSelectedStoreLocations(result));
          // when there are existing result, reset filter when zipCode is changed
          if (nearByRetailerLocations?.length > 0) {
            setRetailerId("0");
            onZipCodeQueryParametersChange?.(zipCode, null, null);
          }
        } else {
          dispatch(setNearbyRetailerLocations([]));
          dispatch(setSelectedStoreLocations([]));
        }
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biller, zipCode]);

  useEffect(() => {
    if (!biller) return;

    if (useRealLocation && curtLocation.lat != 0 && curtLocation.lng != 0) {
      setIsLoading(true);
      getLocationByGeo(
        `${curtLocation.lat}`,
        `${curtLocation.lng}`,
        searchRadiusInMiles,
        searchResultLimit,
        biller.realm,
        biller.clientId
      ).then((result) => {
        /* @ts-ignore */
        if (result && result.length > 0) {
          dispatch(setNearbyRetailerLocations(result));
          dispatch(setSelectedStoreLocations(result));
          // when there are existing result, reset filter when zipCode is changed
          if (nearByRetailerLocations?.length > 0) {
            setRetailerId("0");
            onCoordinatesQueryParametersChange?.(
              curtLocation.lat.toString(),
              curtLocation.lng.toString(),
              null,
              null
            );
          }
        } else {
          dispatch(setNearbyRetailerLocations([]));
          dispatch(setSelectedStoreLocations([]));
        }
        /* @ts-ignore */
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biller, useRealLocation, curtLocation]);

  useEffect(() => {
    if (nearByRetailerLocations?.length > 0) {
      if (retailerId !== "0") {
        handleRetailerChange(retailerId);
      }
      if (useRealLocation) {
        onCoordinatesQueryParametersChange?.(
          curtLocation.lat.toString(),
          curtLocation.lng.toString(),
          retailerId,
          searchParams.get(queryParameter.storeId)
        );
      } else {
        onZipCodeQueryParametersChange?.(
          zipCode,
          retailerId,
          searchParams.get(queryParameter.storeId)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearByRetailerLocations]);

  return (
    <div style={mapMode ? { padding: "32px" } : { padding: "40px"}}>
      <div title="testdiv" data-testid="testdiv"></div>
      <br />
      <div className= {mapMode ? "retailer-selection-container-map" : "retailer-selection-container" }>
        {mapMode && (
          <div className="retailer-map-header">{t("mapmode-header-label")}</div>
        )}
        <div className={zipClass}>
          <InputField
            defaultValue={defaultZipCode}
            errorText=""
            helperText=""
            label={<span>{t("find-payment-location-label")}</span>}
            placeholder="Enter a ZIP code"
            value={zipCode}
            onChange={(e: { target: { value: string } }) => {
              setZipCode(e.target.value);
              setUseRealLocation(false);
            }}
            icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
            iconAlignment="left"
            maxLength={5}
            title={`${t("zip-code-label")}`}
            data-testid="zipinput"
          />
          <div style={{marginTop : "14px"}}>
          <TextButton
            onClick={() => getUserRealLocation()}
            hoverStyle="underline"
            icon={<FontAwesomeIcon icon={faLocationArrow} />}
            iconLocation="right"
            data-testid="geolocationbutton"
          >
            {t("use-my-location-label")}
          </TextButton>
          </div>
          
        </div>
        {mapMode && (
          <div className="retailer-icon-button-div">
            <IconButton
              icon={<FontAwesomeIcon icon={faSliders} />}
              onClick={() => {
                setRetailerSheetIsOpen(true);
              }}
              variant="outline"
              dataCy="icon-button-retailer-filter"
              className="retailer-icon-button"
            />
          </div>
        )}
        <div className={filterClass}>
          <Select
            label={<span>{t("retailer-filter-label")}</span>}
            name="retailerSelect"
            defaultValue={getSelectedRetailerOption()}
            onChange={(option: MenuOption[]) => {
              handleRetailerChange(option[0].id as string);
            }}
            options={getRetailerOptions()}
            /* @ts-ignore */
            //TODO delete ts ignore after this component has dataCy attribute
            dataCy="select-filter-retailer"
          />
        </div>
      </div>
      {mapMode && (
        <div>
          <div className="retailer-mobile-toggle" data-testid="retailer-accordion-container">
            <StyledAccordionWrapper>
              <Accordion
                onClick={handleAccordionChange}
                header={`${t(accordionHeader)}`}
                content={getRetailersForDisplay()}
                className="retailer-locations-accordion"
                /* @ts-ignore */
                //TODO delete ts ignore after this component has dataCy attribute
                dataCy="accordion-mobile-retailer-locations"
                contentPadding="0px"
              />
            </StyledAccordionWrapper>
          </div>
          <div className="retailer-standard-display-map">
            {getRetailersForDisplay()}
          </div>
        </div>
      )}
      {!mapMode && (
        <div className="retailer-standard-display">
          {getRetailersForDisplay()}
        </div>
      )}

      <Sheet
        isOpen={retailerSheetIsOpen}
        onClose={() => setRetailerSheetIsOpen(false)}
        detent="content-height"
        disableDrag={true}
        data-testid="retailer-sheet"
      >
        <Sheet.Container>
          <Sheet.Header>
            <div className="react-modal-sheet-header">
              <div className="help-modal-header-text">
                {t("retailer-filter-label")}
              </div>
              <button
                className="modal-close"
                onClick={() => setRetailerSheetIsOpen(false)}
                aria-label={`${t("close-modal", {
                  ns: COMMON_NAMESPACE,
                })}`}
                data-cy="modal-close-x-payment-amount"
              >
                <img
                  src={XButton}
                  alt={`${t("close-modal", {
                    ns: COMMON_NAMESPACE,
                  })}`}
                  className="modal-close-x"
                />
              </button>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <div className="retailer-sheet-radio-group">
              <div style={{ marginTop: "15px" }}>
                {t("select-preferred-retailer")}
              </div>
              <RadioGroup
                groupName="retailer"
                defaultValue={defaultRetailerId ?? "0"}
                label=""
                onChange={(e) => {
                  setTempRadioRetailerId(e.currentTarget.value);
                }}
              >
                {getRetailerSheetContent()}
              </RadioGroup>
            </div>
            <div className="retailer-sheet-apply">
              <Button
                onClick={() => {
                  setRetailerId(tempRadioRetailerId);
                  handleRetailerChange(tempRadioRetailerId);
                }}
                variant="filled"
                className="retailer-sheet-apply-button"
              >
                {t("apply")}
              </Button>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
};
