import React, { useState, useEffect, useRef } from "react";
import Sheet from "react-modal-sheet";
import Modal from "react-modal";
import { customStyles } from "constants/customStyles";
import { useIdleTimer } from "hooks";
import { SESSION_TIMEOUT } from "constants/i18n-namespaces";
import { useTranslation } from "react-i18next";
import { millisToMinutesAndSeconds } from "utilities/helper";
import { TextButton } from "kubra-ux-forge";
import { isMobile } from "react-device-detect";
import IamApi from "api/IamApi";

export const SessionTimeoutModal = (props: { logout: any }) => {
  const { t } = useTranslation(SESSION_TIMEOUT);
  const [showSessionTimeoutModal, setShowSessionTimeoutModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const idleTime = 5; // in minutes
  const remainingTimeInterval = 90; // in seconds
  const sessionExpireMessageTime = 2000; // in milliseconds
  const intervalRef = useRef<any>(null);
  const lastUpdateTimeRef = useRef<Date | null>(null);

  const setSessionModalIsOpenToTrue = () => {
    setShowSessionTimeoutModal(true);
    setRemainingTime(remainingTimeInterval);
    lastUpdateTimeRef.current = new Date();
    startInterval();
  };

  const setSessionModalIsOpenToFalse = () => {
    setShowSessionTimeoutModal(false);
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const handleLogOut = () => {
    setSessionModalIsOpenToFalse();
    props.logout();
  };

  const handleStayLoggedIn = () => {
    setSessionModalIsOpenToFalse();
    IamApi.ezpay
      .getExtendedSession()
      .then((res) => {
        console.log("your session is extended");
      })
      .catch((error) => {
        console.log("error while extending session ", error);
        props.logout();
      });
  };

  const handleSessionIdleTimer = () => {
    setSessionModalIsOpenToTrue();
  };

  const { isIdle } = useIdleTimer({
    onIdle: handleSessionIdleTimer,
    idleTime: idleTime,
  });

  const startInterval = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setRemainingTime((prevRemainingTime) => Math.max(prevRemainingTime - 1, 0));
    }, 1000);
  };

  useEffect(() => {
    if (isIdle && showSessionTimeoutModal) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      if (document.visibilityState === 'visible') {
        startInterval();
      }
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, showSessionTimeoutModal]);

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && isIdle && showSessionTimeoutModal) {
      const now = new Date();
      const diffSec = Math.floor((now.getTime() - (lastUpdateTimeRef.current ?.getTime() ?? now.getTime())) / 1000);
      setRemainingTime((prev) => Math.max(prev - diffSec, 0));
      startInterval();
    } else if (document.visibilityState === 'hidden' && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    if (remainingTime === 0 && showSessionTimeoutModal) {
      const timer = setTimeout(() => {
        setShowSessionTimeoutModal(false);
        props.logout();
      }, sessionExpireMessageTime);
      return () => clearTimeout(timer);
    }
  }, [remainingTime, showSessionTimeoutModal, props]);

  useEffect(() => {
    lastUpdateTimeRef.current = new Date();
  }, [remainingTime]);

  return (
    isIdle && (
      <div>
        {isMobile ? (
          <Sheet
            isOpen={showSessionTimeoutModal}
            onClose={() => setSessionModalIsOpenToFalse}
            disableDrag={true}
            detent="content-height"
          >
            <Sheet.Container>
              <Sheet.Header>
                <div className="react-modal-sheet-header">
                  <div className="help-modal-header-text">
                    {t("session-timeout-header")}
                  </div>
                </div>
              </Sheet.Header>
              <Sheet.Content>
                {remainingTime !== 0 && showSessionTimeoutModal ? (
                  <div className="session-description-container">
                    <span className="session-description-text">
                      {t("session-expire-message")}
                    </span>
                    <span className="session-description-text">
                      {t("session-security-text")}{" "}
                      <span className="seconds-text">
                        {millisToMinutesAndSeconds(remainingTime * 1000)}{" "}
                        {t("seconds")}
                      </span>
                    </span>
                    <div className="session-buttons-container">
                      <div className="end-session-button">
                        <TextButton onClick={handleLogOut}>
                          {t("end-session")}
                        </TextButton>
                      </div>
                      <div className="continue-session-button">
                        <button
                          className="close-button"
                          onClick={handleStayLoggedIn}
                        >
                          {t("continue-session")}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="session-description-container">
                    <span className="session-description-text">
                      {t("session-expired")}
                    </span>
                  </div>
                )}
              </Sheet.Content>
            </Sheet.Container>
            {/* @ts-ignore */}
            <Sheet.Backdrop onClick={setSessionModalIsOpenToFalse} />
          </Sheet>
        ) : (
          // @ts-ignore
          <Modal
            isOpen={showSessionTimeoutModal}
            ariaHideApp={false}
            className="session-modal-frame"
            shouldCloseOnOverlayClick={false}
            onRequestClose={setSessionModalIsOpenToFalse}
            style={customStyles}
          >
            <div className="modal-header">
              <div className="help-modal-header-text">
                {t("session-timeout-header")}
              </div>
            </div>
            {remainingTime !== 0 && showSessionTimeoutModal ? (
              <div className="session-description-container">
                <span className="session-description-text">
                  {t("session-expire-message")}
                </span>
                <span className="session-description-text">
                  {t("session-security-text")}{" "}
                  <span className="seconds-text">
                    {millisToMinutesAndSeconds(remainingTime * 1000)}{" "}
                    {t("seconds")}
                  </span>
                </span>
                <div className="session-buttons-container">
                  <div className="end-session-button">
                    <TextButton onClick={handleLogOut}>
                      {t("end-session")}
                    </TextButton>
                  </div>
                  <div className="continue-session-button">
                    <button
                      className="close-button"
                      onClick={handleStayLoggedIn}
                    >
                      {t("continue-session")}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="session-description-container">
                <span className="session-description-text">
                  {t("session-expired")}
                </span>
              </div>
            )}
          </Modal>
        )}
      </div>
    )
  );
};
