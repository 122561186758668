import React, { useEffect, useState } from "react";
import { Badge, LoadingButton, TextButton } from "kubra-ux-forge";
import { BillerLogo } from "components/ezpay/common/biller-logo";
import { useSelector } from "react-redux";
import { getBiller } from "selectors";
import {
  getPaymentIntent,
  getPaymentMethodLabel,
} from "selectors/paymentIntent";
import { useNavigate, useOutletContext } from "react-router";
import { useCurrencyFormatter, useDateFormatter } from "hooks";
import { ContactAndTocFooter } from "components/ezpay/common/contact-and-toc-footer";
import { useTranslation } from "react-i18next";
import {
  BALANCE_NAMESPACE,
  COMMON_NAMESPACE,
  PAYMENT_RECEIPT_NAMESPACE,
} from "constants/i18n-namespaces";
import FundingOption from "constants/FundingOption";
import google_pay_mark from "assets/google-pay-mark.png";
import apple_pay_mark from "assets/apple-pay-mark.svg";
import paypal_mark from "assets/paypal-mark.png";
import { SCHEDULED } from "constants/paymentStatus";
import {
  getBadgeStyle,
  getPaymentTotalClass,
  getPaymentTotalLabelClass,
  getPaymentTotalValueClass,
  mappingPaymentStatus,
  upperCaseFirstLetterInString,
} from "utilities/helper";
import { postInitiateIntent } from "api/IamApi/entities/ezpay";
import { ISession } from "types/session";
import { isMobile } from "react-device-detect";

export const PaymentReceipt = (props: any) => {
  const { t } = useTranslation([PAYMENT_RECEIPT_NAMESPACE, BALANCE_NAMESPACE]);

  const { account, login, logout } = useOutletContext<ISession>();
  const biller = useSelector(getBiller);
  const paymentIntentState = useSelector(getPaymentIntent);
  const paymentMethod = paymentIntentState.paymentMethod;
  const paymentMethodLabel = useSelector((state) => getPaymentMethodLabel(state, t));
  const navigate = useNavigate();
  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter();
  const [makeAnotherPaymentLoading, setMakeAnotherPaymentLoading] =
    useState("text");

  // @ts-ignore
  const imageResources: any = biller?.properties?.find((element: any) => {
    return element.name === "ImageResources";
  });
  const contactUs: any = biller?.properties?.find((element: any) => {
    return element.name === "ContactUs";
  });

  const billerLogo = imageResources?.properties?.logoURL ?? "";
  const billerUrl = contactUs?.properties?.website;
  let charitiesTotal = 0.0;
  if (
    paymentIntentState.charities != null &&
    paymentIntentState.charities.length > 0
  ) {
    charitiesTotal = paymentIntentState.charities
      .filter((ch) => ch.selected)
      .reduce((charitiesTotal, charity) => charitiesTotal + charity.amount, 0);
  }

  useEffect(() => {
    window.setTimeout(RemoveOverflow, 50);
  }, []);

  function RemoveOverflow() {
    document.body.style.removeProperty('overflow');
  }

  const totalPayment =
    paymentIntentState.paymentAmount +
    charitiesTotal +
    paymentIntentState.serviceFee;

  const handleMakeAnotherPayment = () => {
    setMakeAnotherPaymentLoading("loading");

    postInitiateIntent(
      biller.clientId,
      account.realm,
      account.fingerprint,
      navigator.userAgent
    )
      .then((res) => {
        login({ ...account, paymentIntentId: res.data.intentId });
        setMakeAnotherPaymentLoading("text");
        navigate("/");
      })
      .catch((err) => {
        setMakeAnotherPaymentLoading("text");
      });
  };

  if (biller === null) return <></>;

  paymentIntentState.intentId = "";

  const checkChangeAccountRender = () => {
    if (account.channel.trim() != "" && account.channel !== "CallCenter") {
      return (<div className="payment-receipt-button-item">      
        <TextButton onClick={logout}>
          {t("change-account")}
        </TextButton>
      </div>);
    }
    return null;
  }

  return (
    <div className="payment-receipt-frame">
      <div className="common-header" style={{ width: "100%" }}>
        <BillerLogo billerLogo={billerLogo} billerUrl={billerUrl} />
      </div>
      <div className="payment-receipt-container">
        <div className="payment-receipt-title-container">
          <div className="payment-receipt-title">
            {t(
              `payment-title-${mappingPaymentStatus(
                paymentIntentState.paymentStatus
              )}`
            )}
          </div>

          {paymentIntentState.receiptInfo?.mobileNumber !== "" && (
            <div className="payment-receipt-sub-title">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("receipt-delivery-info-email-phone", {
                    email: paymentIntentState.receiptInfo?.email,
                    mobileNumber: paymentIntentState.receiptInfo?.mobileNumber,
                    interpolation: {
                      escapeValue: false,
                    },
                  }),
                }}
              />
            </div>
          )}
          {paymentIntentState.receiptInfo?.mobileNumber === "" && (
            <div className="payment-receipt-sub-title">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("receipt-delivery-info-email-only", {
                    email: paymentIntentState.receiptInfo?.email,
                  }),
                }}
              />
            </div>
          )}
        </div>

        <div className="payment-receipt-details-container">
          <div className="payment-receipt-details-row">
            <div className="payment-receipt-details-left-label">
              {t("account-number-label")}
            </div>
            <div className="review-charges-right-label">
              {paymentIntentState.accountNumber}
            </div>
          </div>

          <div className="payment-receipt-details-row">
            <div className="payment-receipt-details-left-label">
              {t("confirmation-number")}
            </div>
            <div className="review-charges-right-label">
              {paymentIntentState.paymentId}
            </div>
          </div>

          <div className="payment-receipt-details-row">
            <div className="payment-receipt-details-left-label">
              {t("status")}
            </div>
            <div className="review-charges-right-label">
              <Badge
                text={upperCaseFirstLetterInString(
                  t(mappingPaymentStatus(paymentIntentState.paymentStatus), {
                    ns: COMMON_NAMESPACE,
                  })
                )}
                variant={getBadgeStyle(paymentIntentState.paymentStatus)}
              />
            </div>
          </div>

          {/* These fields show in scheduled status only */}
          {mappingPaymentStatus(paymentIntentState.paymentStatus) ===
            SCHEDULED && (
              <div className="payment-receipt-details-container">
                <div className="payment-receipt-details-row">
                  <div className="payment-receipt-details-left-label">
                    {t("payment-date")}
                  </div>
                  <div className="review-charges-right-label">
                    {dateFormatter.shortFormat(paymentIntentState.paymentDate)}
                  </div>
                </div>

                <div className="payment-receipt-details-row">
                  <div className="payment-receipt-details-left-label">
                    {t("payment-method")}
                  </div>
                  <div className="review-charges-right-label">
                    {paymentMethod == FundingOption.ApplePay && (
                      <img
                        src={apple_pay_mark}
                        alt="Apple Pay"
                        style={{ height: "30px" }}
                      ></img>
                    )}
                    {paymentMethod == FundingOption.GooglePay && (
                      <img
                        src={google_pay_mark}
                        alt="Google Pay"
                        style={{ height: "30px" }}
                      ></img>
                    )}
                    {paymentMethod == FundingOption.PayPal && (
                      <img src={paypal_mark} alt="PayPal"></img>
                    )}
                    {paymentMethodLabel}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      <div className={getPaymentTotalClass(paymentIntentState.paymentStatus)}>
        <div
          className={getPaymentTotalLabelClass(
            paymentIntentState.paymentStatus
          )}
        >
          {t("payment-total")}
        </div>
        <div
          className={getPaymentTotalValueClass(
            paymentIntentState.paymentStatus
          )}
        >
          {currencyFormatter.format(totalPayment)}
        </div>
      </div>

      <div className="payment-receipt-buttons-row">
        {checkChangeAccountRender()}          
        <div className="payment-receipt-button-item">
          <LoadingButton
            type="button"
            onClick={handleMakeAnotherPayment}
            loadingState={makeAnotherPaymentLoading}
          >
            {t("make-another-payment")}
          </LoadingButton>
        </div>
      </div>

      <ContactAndTocFooter biller={biller} isMobile={isMobile} />
    </div>
  );
};
export default PaymentReceipt;