import React from "react";
import { ValidationContainer } from "../validation-container";
import { ThemeProvider } from "react-jss";
import { theme } from "constants/Theme";

export interface IValidationView {
  isMobile: boolean;
  fingerprint: string;
}

export const ValidationView = (props: IValidationView) => {
  const { isMobile, fingerprint } = props;
 
  return (
    // @ts-ignore
    <ThemeProvider theme={theme}>
      <ValidationContainer isMobile={isMobile} fingerprint={fingerprint} />
    </ThemeProvider>
  );
};
export default ValidationView;