import React, { useEffect, useState } from "react";
import { ACTION } from "actions/paymentIntentActions";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentIntent } from "selectors/paymentIntent";
import { useTranslation } from "react-i18next";
import {
  COMMON_NAMESPACE,
  PAYMENT_AMOUNT_MODAL_NAMESPACE,
} from "constants/i18n-namespaces";
import { Button, RadioInput } from "kubra-ux-forge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

export interface IPartialPaymentAmount {
  fullBalanceAmount: number;
  allowPartialPayment: boolean;
  updateCallBack: (amount: number) => void;
}

export const PartialPaymentAmount = (props: IPartialPaymentAmount) => {
  const { fullBalanceAmount, allowPartialPayment, updateCallBack } = props;

  const [fullBalanceChecked, setFullBalanceChecked] = useState(true);
  const [otherPaymentAmount, setOtherPaymentAmount] = useState<
    number | undefined
  >(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const dispatch = useDispatch();
  const { t } = useTranslation(PAYMENT_AMOUNT_MODAL_NAMESPACE);
  const paymentIntentState = useSelector(getPaymentIntent);

  const validateOtherPaymentAmount = () => {
    if (!otherPaymentAmount) {
      setErrorMessage(t("other-input-required"));
      return false;
    }
    setErrorMessage(undefined);
    return true;
  };

  const handleBalanceChecked = () => {
    setFullBalanceChecked(true);
  };

  const handleBalanceUnchecked = () => {
    setFullBalanceChecked(false);
  };  
  
  useEffect(() => {

    if (paymentIntentState.paymentAmount != fullBalanceAmount) {
      setFullBalanceChecked(false);
      setOtherPaymentAmount(paymentIntentState.paymentAmount);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {
    if (fullBalanceChecked) {
      dispatch({ type: ACTION.ADD_PAYMENT_AMOUNT, payload: fullBalanceAmount });
      updateCallBack(fullBalanceAmount);
    } else {
      if (validateOtherPaymentAmount() && otherPaymentAmount) {
        dispatch({
          type: ACTION.ADD_PAYMENT_AMOUNT,
          payload: otherPaymentAmount,
        });
        updateCallBack(otherPaymentAmount);
      }
    }
  };

  return (
    // @ts-ignore
    <div className="balance-amount-frame-container">
      <div className="balance-amount-frame">
        <div className="ez-pay-balance-frame-small">
          <RadioInput
            selected={fullBalanceChecked}
            label={t("full-balance")}
            icon={<FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>}
            onSelect={handleBalanceChecked}
            data-cy="radio-full-balance"
            value={fullBalanceAmount}
            onChange={() => undefined}
            disableInput
          />
          {allowPartialPayment && (
            <RadioInput
              selected={!fullBalanceChecked}
              label={t("other")}
              icon={<FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>}
              onSelect={handleBalanceUnchecked}
              value={otherPaymentAmount ?? ""}
              errorMessage={errorMessage}
              onChange={(number) => {
                setOtherPaymentAmount(number);
              }}              
            />
          )}
        </div>
        <Button
          type="submit"
          className="ez-pay-balance-done"
          title="continue button"
          data-cy="button-continue"
          onClick={() => handleSubmit()}
        >
          {t("done", { ns: COMMON_NAMESPACE })}
        </Button>
      </div>
    </div>
  );
};
