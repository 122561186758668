import _ from 'lodash';
import config from './default';

const dev = _.cloneDeep(config);

dev.api = {
  ez_pay: {
    hostInstances: 'https://api.dev.ez-pay.co/ez-pay/v1',
  },
  rcp: {
    hostInstances: 'https://api.dev.kubra.io/rcp/v1',
  }
};

export default dev;
