// @ts-nocheck
import React, { ReactNode, useState } from "react";
import {
  Accordion,
  AccordionGroup,
  Badge,
  LoadingButton,
  LoadingState,
  TextButton,
  Tooltip,
} from "kubra-ux-forge";
import { useDispatch, useSelector } from "react-redux";
import { getBiller } from "selectors";
import { useCurrencyFormatter, usePaymentHistory } from "hooks";
import { useTranslation } from "react-i18next";
import {
  COMMON_NAMESPACE,
  RECENT_PAYMENTS_NAMESPACE,
  PAYMENT_METHOD_MODAL_NAMESPACE,
} from "constants/i18n-namespaces";
import {
  ApplePay,
  BankAccount,
  Card,
  CreditCard,
  Debit,
  GooglePay,
  PayPal,
  PinlessDebit,
  CreditCardAmex,
  Cash,
} from "constants/FundingSourceType";
import { SCHEDULED } from "constants/paymentStatus";
import {
  mappingPaymentStatus,
  upperCaseFirstLetterInString,
  mappingDonataionResponseToCharityState,
  getBadgeStyle,
  paymentMethodLabel,
} from "utilities/helper";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";
import Sheet from "react-modal-sheet";
import XButton from "assets/icons/x_button.svg";
import IamApi from "api/IamApi";
import { DONE, EMPTY, ERROR, IN_PROGRESS } from "constants/Status";
import routing from "routing";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ISession } from "types/session";
import {
  IFundingSource,
  IPaymentRecord,
} from "types/Api/Payment/paymentRecord";
import moment from "moment";
import { ContactAndTocFooter } from "components/ezpay/common/contact-and-toc-footer";
import styled from "styled-components";
import _ from "lodash";
import { setAppLoading } from "actions/appStateActions";
import { PageLoader } from "components/ezpay/common/page-loader";
import FundingOption from "constants/FundingOption";

export const StyledAccordionWrapper = styled.div`
  width: 100%;
  > div > div > div > div label {
    font-size: 1.8rem;
  }
`;

export const RecentPayments = (props: { isMobile: boolean }) => {
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] =
    useState<boolean>(false);
  const { account } = useOutletContext<ISession>();
  const [cancelState, setCancelState] = useState(EMPTY);
  const [cancelErrorMessage, setCancelErrorMessage] = useState<string>();
  const [loadingStatus, setLoadingStatus] = useState(false);
  const { t, i18n } = useTranslation([RECENT_PAYMENTS_NAMESPACE, PAYMENT_METHOD_MODAL_NAMESPACE ]);
  const { loadUpcomingPayment, getUpcomingPayment, getRecentPayments } = usePaymentHistory();
  const dispatch = useDispatch();

  const biller = useSelector(getBiller);
  const navigate = useNavigate();
  const currencyFormatter = useCurrencyFormatter();
  const upcomingPayment = getUpcomingPayment();
  const recentPayments = getRecentPayments();

  const cancelPaymentIntent = async (paymentId: string, paymentIntentId: string) => {
    dispatch(setAppLoading(true));
    try {
      setCancelState(IN_PROGRESS);
      await IamApi.ezpay.cancelUpcomingPayment(
        paymentId,
        paymentIntentId,
        biller.clientId,
        account.realm
      );
      setCancelState(DONE);
    } catch (error: any) {
      setCancelState(ERROR);
      setCancelErrorMessage(error.message);
    }
    dispatch(setAppLoading(false));
  };

  const getLoadingButtonState = (): LoadingState => {
    switch (cancelState) {
      case EMPTY:
        return "text";
      case IN_PROGRESS:
        return "loading";
      case DONE:
        return "success";
      case ERROR:
        return "text";
      default:
        return "text";
    }
  };

  const getPaymentMethod = (fundingSource: IFundingSource) => {
    switch (fundingSource.fundingSourceType) {
      case BankAccount:
        return (
          (fundingSource.methodOfPayment ?? "") +
          " *" +
          fundingSource.bankAccountDetails.maskedPaymentAccount
        );
      case CreditCard:
      case Debit:
      case Card:
      case PinlessDebit:
      case CreditCardAmex:
        return (
          (fundingSource.methodOfPayment ?? "") +
          " *" +
          fundingSource.cardDetails.maskedPaymentAccount
        );
      case GooglePay:
        return GooglePay;
      case ApplePay:
        return ApplePay;
      case PayPal:
        return PayPal;
      case Cash:
        return t("cash", { ns: PAYMENT_METHOD_MODAL_NAMESPACE });
      default:
        return "";
    }
  };

  const reloadUpcomingPayment = async () => {
    setLoadingStatus(true);
    setConfirmCancelModalOpen(false);
    loadUpcomingPayment(biller.clientId, account.realm);
    setLoadingStatus(false);
  }

  const createPaymentIntentContent = (payment: IPaymentRecord): ReactNode => {
    if (!payment?.itemTransactions?.[0]) {
      return <></>;
    }

    const mappedStatus = mappingPaymentStatus(
      payment.itemTransactions[0].status
    );

    const charities = mappingDonataionResponseToCharityState(
      payment.donations,
      account.charities
    );
    const selectedCharities = charities?.filter((char) => char.selected);

    return (
      <div
        className="payment-receipt-container"
        style={{ padding: "0px 72px 0px 0px" }}
      >
        <div className="payment-receipt-details-container">
          <div></div>
          <div className="payment-receipt-details-row">
            <div className="payment-receipt-details-left-label">
              {t("confirmation-number")}
            </div>
            <div className="review-charges-right-label">{payment.id}</div>
          </div>
          <div className="payment-receipt-details-row">
            <div className="payment-receipt-details-left-label">
              {t("status")}
            </div>
            <div className="review-charges-right-label">
              <Badge
                text={upperCaseFirstLetterInString(
                  t(mappedStatus, {
                    ns: COMMON_NAMESPACE,
                  })
                )}
                variant={getBadgeStyle(payment.itemTransactions[0].status)}
              />
            </div>
          </div>
          <div className="payment-receipt-details-row">
            <div className="payment-receipt-details-left-label">
              {mappedStatus === SCHEDULED
                ? t("scheduled-payment-date")
                : t("payment-date")}
            </div>
            <div className="review-charges-right-label">
              {moment.utc(payment.paymentDate).format("MM/DD/YY")}
            </div>
          </div>

          <div className="payment-receipt-details-row">
            <div className="payment-receipt-details-left-label">
              {t("payment-method")}
            </div>
            <div className="review-charges-right-label">
              {paymentMethodLabel(getPaymentMethod(payment.fundingSource))}
            </div>
          </div>

          {
            payment.fundingSource.fundingSourceType == FundingOption.Cash &&
            (              
              <div className="payment-receipt-details-row">
              <div className="payment-receipt-details-left-label">
                {t("retailer-name")}
              </div>
              <div className="review-charges-right-label">
               {payment.itemIncommContext?.retailerName}
              </div>
          </div>
          )}
          

          <div className="payment-receipt-details-row">
            <div className="payment-receipt-details-left-label">
              {t("payment-amount")}
            </div>
            <div className="review-charges-right-label">
              {currencyFormatter.format(payment.paymentAmount)}
            </div>
          </div>

          {payment.feeAmount > 0 && (
             <div className="payment-receipt-details-row">
              <div
                className="payment-receipt-left-service-fee-label-container"
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                data-testid="service-fee-label-container"
                aria-hidden="true"
              >
                <span className="payment-receipt-details-left-label">
                  {t("service-fee")}{" "}
                </span>
                {!props.isMobile && <Tooltip direction="Right" content={`${t("service-fee-tooltip")}`} />}
              </div>
              <div className="review-charges-right-label">
                {currencyFormatter.format(payment.feeAmount)}
              </div>
             </div>
          )}
          

          {selectedCharities != undefined && payment.fundingSource.fundingSourceType != FundingOption.Cash && 
            selectedCharities.length > 0 &&
            selectedCharities.map(
              (charityState) =>
                charityState.selected &&
                charityState.amount > 0 && (
                  <div
                    className="payment-receipt-details-row"
                    key={payment.id + "-" + charityState.charity.code}
                  >
                    <div className="payment-receipt-details-left-label">
                      {t("donation-to")}
                    </div>
                    <div className="review-charges-right-label">
                      {currencyFormatter.format(charityState.amount)}
                    </div>
                  </div>
                )
            )}          

          <div className="payment-receipt-details-row">
            <div className="payment-receipt-left-service-fee-label-container">
              <div className="payment-receipt-details-left-label">
                {t("payment-total")}
              </div>
            </div>
            <div className="review-charges-right-label">
              {currencyFormatter.format(payment.totalAmount)}
            </div>
          </div>
        </div>

        <div className="review-charges-bottom-text">
          {t("charges-disclaimer")}
        </div>

        {mappedStatus === SCHEDULED && (
          <div className="payment-receipt-buttons-row">
            <div className="payment-receipt-button-item cancel-payment">
              <TextButton
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setConfirmCancelModalOpen(true);
                }}
                data-cy="cancel-button"
              >
                {t("cancel-payment")}
              </TextButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  const confirmCancelScheduledPaymentModal = (
    upcomingPayment: IPaymentRecord
  ) => {
    if (isMobile) {
      return (
        <Sheet
          isOpen={confirmCancelModalOpen}
          onClose={() => setConfirmCancelModalOpen(false)}
          detent="content-height"
          disableDrag={true}
        >
          <Sheet.Container>
            <Sheet.Header>
              <div className="react-modal-sheet-header">
                <div className="help-modal-header-text">
                  {cancelState === DONE
                    ? t("cancel-payment-modal-title-success")
                    : t("cancel-payment-modal-title")}
                </div>
                <button
                  className="modal-close"
                  onClick={() => setConfirmCancelModalOpen(false)}
                  aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                >
                  <img
                    src={XButton}
                    alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                    className="modal-close-x"
                  />
                </button>
              </div>
            </Sheet.Header>
            <Sheet.Content>
              <div className="cancel-payment-content-container">
                <div className="cancel-payment-content">
                  {cancelState === DONE
                    ? t("cancel-payment-modal-content-success")
                    : t("cancel-payment-modal-content", {
                      date: moment
                        .utc(upcomingPayment.paymentDate)
                        .format("MM/DD/YY"),
                      interpolation: { escapeValue: false },
                    })}
                </div>
                <div className="comfirm-cancel-modal-buttons-row">
                  {cancelState === DONE ? (
                    <button
                      className="done-button"
                      onClick={() => reloadUpcomingPayment()}
                    >
                      {t("Close")}
                    </button>
                  ) : (
                    <>
                      <TextButton
                        onClick={() => setConfirmCancelModalOpen(false)}
                      >
                        {t("no-cancel")}
                      </TextButton>
                      <div>
                        <LoadingButton
                          loadingState={getLoadingButtonState()}
                          errorMessage={cancelErrorMessage}
                          className="done-button"
                          onClick={() =>
                            cancelPaymentIntent(upcomingPayment.id, upcomingPayment.paymentIntentId)
                          }
                        >
                          {t("yes-cancel")}
                        </LoadingButton>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      );
    }

    return (
      /* @ts-ignore */
      <Modal
        isOpen={confirmCancelModalOpen}
        ariaHideApp={false}
        className="modal-frame-help"
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setConfirmCancelModalOpen(false)}
      >
        <div className="modal-header">
          <div className="help-modal-header-text">
            {cancelState === DONE
              ? t("cancel-payment-modal-title-success")
              : t("cancel-payment-modal-title")}
          </div>
          <button
            className="modal-close"
            onClick={() => setConfirmCancelModalOpen(false)}
            aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
          >
            <img
              src={XButton}
              alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
              className="modal-close-x"
            />
          </button>
        </div>
        <div className="cancel-payment-content-container">
          <div className="cancel-payment-content">
            {cancelState === DONE
              ? t("cancel-payment-modal-content-success")
              : t("cancel-payment-modal-content", {
                date: moment
                  .utc(upcomingPayment.paymentDate)
                  .format("MM/DD/YY"),
                interpolation: { escapeValue: false },
              })}
          </div>
          <div className="comfirm-cancel-modal-buttons-row">
            {cancelState === DONE ? (
              <button
                className="done-button"
                onClick={() => reloadUpcomingPayment()}
              >
                {t("Close")}
              </button>
            ) : (
              <>
                <TextButton onClick={() => setConfirmCancelModalOpen(false)}>
                  {t("no-cancel")}
                </TextButton>
                <div>
                  <LoadingButton
                    loadingState={getLoadingButtonState()}
                    errorMessage={cancelErrorMessage}
                    className="done-button"
                    onClick={() => cancelPaymentIntent(upcomingPayment.id, upcomingPayment.paymentIntentId)}
                  >
                    {t("yes-cancel")}
                  </LoadingButton>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  };

  const returnAccordionHeader = (status: string) => {
    const mappedStatus = mappingPaymentStatus(status);
    switch (mappedStatus) {
      case "paid":
        return t(`payment-title-approved`);
      case "refunded":
        return t(`payment-title-refund`);
      case "pending":
        return t(`payment-title-pending`);
      case "cancelled":
        return t(`payment-title-cancelled`);
      case "failed":
        return t(`payment-title-failed`);
      default:
        return undefined;
    }
  };

  const sortedPayments = [...recentPayments].sort((a, b) => {
    const dateA = new Date(a.created);
    const dateB = new Date(b.created);
    return dateA - dateB;
  });

  const createRecentPaymentsAccordionGroup = () => {
    return (
      <AccordionGroup>
        {sortedPayments?.map((payment) => (
          <StyledAccordionWrapper key={i18n.language + payment?.id}>
            <Accordion
              id={payment?.id}
              header={
                returnAccordionHeader(payment?.itemTransactions[0]?.status) +
                " " +
                moment.utc(payment?.paymentDate).format("MM/DD/YY")
              }
              content={createPaymentIntentContent(payment)}
              key={payment?.id}
              iconPosition="left"
              data-testid="recentPaymentAccordion"
            />
          </StyledAccordionWrapper>
        ))}
      </AccordionGroup>
    );
  };

  const createUpcomingAndRecentPaymentsAccordionGroup = () => {
    if (!_.isEmpty(upcomingPayment)) {
      return (
        // key to force render the accordion group when upcomingPayment is cancelled
        <AccordionGroup
          key={i18n.language + upcomingPayment.id + recentPayments.length}
        >
          {!_.isEmpty(upcomingPayment) && (
            <StyledAccordionWrapper key={i18n.language + upcomingPayment.id}>
              <Accordion
                id={upcomingPayment.id}
                key={upcomingPayment.id}
                iconPosition="left"
                header={
                  t(`payment-title-scheduled`) +
                  " " +
                  moment.utc(upcomingPayment.paymentDate).format("MM/DD/YY")
                }
                content={createPaymentIntentContent(upcomingPayment)}
              ></Accordion>
            </StyledAccordionWrapper>
          )}
          {sortedPayments?.map((payment) => (
            <StyledAccordionWrapper key={i18n.language + upcomingPayment.id}>
              <Accordion
                id={payment?.id}
                header={
                  returnAccordionHeader(payment?.itemTransactions[0]?.status) +
                  " " +
                  moment.utc(payment?.paymentDate).format("MM/DD/YY")
                }
                content={createPaymentIntentContent(payment)}
                key={payment?.id}
                iconPosition="left"
                data-testid="recentPaymentAccordion"
              />
            </StyledAccordionWrapper>
          ))}
        </AccordionGroup>
      );
    }
  };

  return (
    <div className="payment-receipt-frame" style={{ gap: 0 }}>
      <div
        className="common-header payment-receipt-frame-header"
        style={{ width: "100%" }}
      >
        <div className="back-to-payment-dashboard">
          <button
            className="ez-pay-button-link"
            onClick={() => navigate(routing.balance.index())}
            data-cy="back-to-payments"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span> {t("back-to-payment-dashboard")}</span>
          </button>
        </div>
      </div>
      <PageLoader />
      {!loadingStatus &&
        !_.isEmpty(upcomingPayment) &&
        createUpcomingAndRecentPaymentsAccordionGroup()}

      {!loadingStatus &&
        _.isEmpty(upcomingPayment) &&
        createRecentPaymentsAccordionGroup()}

      <ContactAndTocFooter biller={biller} />
      {/* confirm cancel modal */}
      {!_.isEmpty(upcomingPayment) &&
        confirmCancelScheduledPaymentModal(upcomingPayment)}
    </div>
  );
};

export default RecentPayments;
