/* eslint-disable max-len */
import * as React from "react";

export const WelcomeModalSVG = ({ ...extraProps }) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="19.050990408163265 45.9373508 207.69795918367348 175.54000000000002">
<path fill="#F5F5F5" d="M70.7006 173.158C106.215 175.409 101.683 174.18 121.53 196.744C142.844 226.436 184.878 222.292 204.265 191.037C228.561 151.868 231.05 102.793 185.138 68.6534C154.861 46.1394 141.188 55.2666 89.3898 56.8811C29.4529 58.7492 21.2912 104.327 22.7959 126.766C23.4491 136.506 40.9232 171.271 70.7006 173.158Z"/>
<g clipPath="url(#clip0_1_60)">
<path fill="#64779C" d="M168.898 168.607L148.208 156.314L169.494 120.099L190.184 132.392C193.771 134.504 194.953 139.165 192.839 142.78L179.227 165.939C177.092 169.544 172.442 170.763 168.898 168.618V168.607Z"/>
<path fill="#64779C" d="M182.879 163.62L181.167 162.608L190.856 146.111L192.568 147.124C194.248 148.137 194.801 150.325 193.836 152.013L187.735 162.357C186.727 164.078 184.548 164.633 182.879 163.62Z"/>
<path fill="#64779C" d="M156.944 110.702L169.494 120.131L148.208 156.347L133.956 149.868C120.387 143.694 109.56 141.745 94.7986 139.927L89.2171 139.252C83.0069 138.489 79.6363 131.564 82.7901 126.175L111.554 77.1326C114.741 71.7428 122.392 71.3834 126.055 76.4684L129.372 81.0634C138.075 93.1278 145.022 101.741 156.933 110.691L156.944 110.702Z"/>
<path fill="#64779C" d="M135.452 198.855L141.261 202.307C143.104 203.407 145.531 202.786 146.626 200.935L166.46 167.192L153.877 159.722L134.043 193.466C132.948 195.36 133.566 197.756 135.452 198.855Z"/>
<path fill="#64779C" d="M142.096 177.133L143.255 177.819L149.921 166.44L148.761 165.754C147.601 165.068 146.106 165.46 145.423 166.625L141.218 173.768C140.568 174.901 140.925 176.436 142.085 177.122L142.096 177.133Z"/>
<path fill="#64779C" d="M121.081 65.9827C121.764 66.0263 122.457 66.2332 123.075 66.6143C123.086 66.6143 123.108 66.636 123.118 66.6469C125.232 67.9209 125.936 70.7192 124.657 72.8643L85.3587 139.687C84.47 141.212 82.801 142.028 81.1427 141.919H81.1319C80.4491 141.865 79.7771 141.669 79.1485 141.288C76.9701 139.992 76.2765 137.194 77.5662 135.005L116.854 68.204C117.743 66.6796 119.412 65.863 121.07 65.9827H121.081Z"/>
<path fill="#64779C" d="M166.958 165.667L146.268 153.374L167.554 117.159L188.244 129.452C191.831 131.564 193.013 136.225 190.899 139.84L177.287 163C175.152 166.604 170.502 167.823 166.958 165.678V165.667Z"/>
<path fill="#EBEBEB" d="M180.939 160.669L179.227 159.657L188.916 143.161L190.628 144.173C192.308 145.186 192.861 147.375 191.896 149.062L185.795 159.406C184.787 161.127 182.608 161.682 180.939 160.669Z"/>
<path fill="#EBEBEB" d="M155.004 107.751L167.554 117.181L146.268 153.396L132.016 146.917C118.447 140.743 107.62 138.794 92.8586 136.976L87.2771 136.301C81.0669 135.539 77.6962 128.614 80.8501 123.224L109.625 74.1818C112.811 68.792 120.463 68.4327 124.126 73.5176L127.432 78.1126C136.135 90.1771 143.082 98.7899 154.993 107.74L155.004 107.751Z"/>
<path fill="#00658B" d="M100.088 90.4057L90.3984 106.902C86.876 104.789 86.1824 99.4105 88.8486 94.8373C91.5147 90.2642 96.5652 88.3369 100.088 90.4057Z"/>
<path fill="#CBD2DE" d="M133.512 195.916L139.321 199.367C141.164 200.467 143.591 199.846 144.686 197.995L164.52 164.252L151.937 156.782L132.103 190.526C131.008 192.42 131.626 194.827 133.512 195.916Z"/>
<path fill="#64779C" d="M140.156 174.193L141.315 174.879L147.981 163.5L146.821 162.814C145.661 162.128 144.166 162.52 143.483 163.686L139.278 170.828C138.628 171.961 138.985 173.496 140.145 174.182L140.156 174.193Z"/>
<path fill="#64779C" d="M119.141 63.0211C119.824 63.0646 120.517 63.2715 121.135 63.6526C121.146 63.6526 121.168 63.6744 121.178 63.6853C123.292 64.9592 123.996 67.7685 122.717 69.9026L83.4296 136.747C82.5408 138.272 80.8718 139.088 79.2136 138.969H79.2027C78.5199 138.914 77.848 138.718 77.2194 138.337C75.0409 137.041 74.3473 134.243 75.637 132.054L114.925 65.2532C115.803 63.7288 117.483 62.9122 119.141 63.0211Z"/>
<path fill="#64779C" d="M92.6552 188.87V193.53H86.5273V188.87H81.7229V182.935H86.5353V178.275H92.6552V182.935H97.4596V188.87H92.6552ZM92.0937 188.333H96.8982V183.472H92.0937V178.812H87.0808V183.472H82.2763V188.333H87.0888V192.985H92.1017V188.326L92.0937 188.333Z"/>
<path fill="#D7D7D7" d="M178.671 199.885V195.226H178.687H183.492V189.291H178.687V184.631H172.559V189.291H167.747V195.226H172.551V199.885H178.671Z"/>
</g>
<path fill="#64779C" d="M60.3942 129.853V131.665H57.9311V129.853H56V127.545H57.9343V125.733H60.3942V127.545H62.3253V129.853H60.3942ZM60.1685 129.644H62.0996V127.754H60.1685V125.942H58.1536V127.754H56.2224V129.644H58.1568V131.453H60.1717V129.641L60.1685 129.644Z"/>
<path fill="#64779C" d="M204.189 161.633V163.445H201.726V161.633H199.795V159.325H201.73V157.513H204.189V159.325H206.12V161.633H204.189ZM203.964 161.424H205.895V159.533H203.964V157.721H201.949V159.533H200.018V161.424H201.952V163.233H203.967V161.421L203.964 161.424Z"/>
<path fill="#64779C" d="M95.3942 50.1921V52.0041H92.9311V50.1921H91V47.8839H92.9343V46.0719H95.3942V47.8839H97.3253V50.1921H95.3942ZM95.1685 49.9833H97.0996V48.0927H95.1685V46.2806H93.1536V48.0927H91.2224V49.9833H93.1568V51.7923H95.1717V49.9803L95.1685 49.9833Z"/>
<path fill="#949494" d="M180.311 100.733V98.662H180.319H182.506V96.0241H180.319V93.9533H177.528V96.0241H175.337V98.662H177.525V100.733H180.311Z"/>
<path fill="#D7D7D7" d="M124.227 161.326V159.255H124.234H126.422V156.617H124.234V154.546H121.444V156.617H119.253V159.255H121.44V161.326H124.227Z"/>
<path fill="#949494" d="M70.6727 85.9024V83.8315H70.68H72.8675V81.1936H70.68V79.1227H67.8899V81.1936H65.6988V83.8315H67.8863V85.9024H70.6727Z"/>
<path fill="#D7D7D7" d="M76.645 103.275V100.428H76.6549H79.6145V96.8007H76.6549V93.9533H72.8801V96.8007H69.9157V100.428H72.8752V103.275H76.645Z"/>
<defs>
<clipPath id="clip0_1_60">
<rect transform="translate(74.9759 63.0211)" fill="white" height="139.831" width="119.337"/>
</clipPath>
</defs>
</svg>
);