import React, { useEffect } from "react";
import { ValidationHeader } from "../validation-header";
import { ValidationForm } from "../validation-form";
import { useBiller } from "hooks";
import { useSelector } from "react-redux";
import { getBillerLoadStatus } from "selectors";
import { EMPTY, IN_PROGRESS, ERROR } from "constants/Status";
import { IBillerMessage, IContactInfo, IImageResources } from "types/biller";
import { Spinner } from "kubra-ux-forge";
import { ContactAndTocFooter } from "components/ezpay/common/contact-and-toc-footer";
import { useNavigate } from "react-router-dom";
import routing from "routing";
import i18n from "i18n/I18nApp";

export interface IValidationContainerProps {
  isMobile: boolean;
  fingerprint: string;
}

export const ValidationContainer = (props: IValidationContainerProps) => {

  const { isMobile, fingerprint } = props;

  const { loadBiller, getBiller } = useBiller();
  const loadBillerStatus = useSelector(getBillerLoadStatus);
  const biller = getBiller();
  const navigate = useNavigate();

  useEffect(() => {
    loadBiller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    loadBillerStatus === EMPTY ||
    loadBillerStatus === IN_PROGRESS ||
    loadBillerStatus === ERROR
  ) {
    if (loadBillerStatus === ERROR) {
      console.log("failed to retrieve instance");
      navigate(routing.notFound.index());
    }
    return (
      <div className="validate-frame">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Spinner size="large" variant="primary" />
        </div>
      </div>
    );
  }

  // get required values from subresources
  const imageResources = biller.properties?.find((element) => {
    return element.name === "ImageResources";
  }) as IImageResources;

  const contactUs = biller.properties?.find((element) => {
    return element.name === "InstanceConfiguration";
  }) as IContactInfo;

  

  const billerMessageProperties = biller?.properties?.find((element) => {
    return element.name === "InstanceConfiguration";
  }) as IBillerMessage;

  const billerMessageEnabled = billerMessageProperties?.properties?.billerMessageWebEnabled === "true";

  const billerMessage = () => {
    if (billerMessageEnabled) {
      try {
        const billerMessage = JSON.parse(billerMessageProperties?.properties.billerMessageWeb);
        const msg = billerMessage.find((msg: { language: string; }) => msg.language === i18n.language);        
        return msg?.value;
      } catch (error) {
        return undefined;
      }

    }
  };

  return (
    <div>
      <div className="validate-frame">
        <ValidationHeader
          logoUrl={imageResources?.properties?.logoURL ?? ""}
          billerUrl={contactUs?.properties?.website ?? ""}
        />
        <ValidationForm
          validationFields={biller.validationFields}
          billerId={biller.billerName ?? ""}
          instanceId={biller.instanceId ?? ""}
          billImage={imageResources?.properties?.billSampleURL ?? ""}
          isMobile={isMobile}
          fingerprint={fingerprint}
          accountPlaceholder={biller.accountPlaceholder ?? "xxxxxxxxxx"}
          vcodePlaceholder={biller.vcodePlaceholder ?? "xxxxx"}
          isBillerMessageEnabled = {billerMessageEnabled}
          billerMessage={billerMessage()}
          billerName= {biller.billerName}
        />
        <ContactAndTocFooter biller={biller} isMobile={isMobile} />
      </div>
    </div>
  );
};
